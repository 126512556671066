import React from 'react';
import { LineChartRequest } from './LineChartRequest';
import JiraStatisticFilter from '../shared/JiraStatisticFilter';
import axios from 'axios';
import { saveAs } from 'file-saver';

import './VulnerabilityRemediationExpandedChart.scss';

import { JiraStatisticAppFamilyFilter, JiraStatisticScale } from '../shared/JiraStatisticFilterConstants';
import LineChartBase from './LineChartBase';
import { Button } from 'react-bootstrap';
import { ChartPropsBase } from './ChartPropsBase';

const CsvExport: React.FC<ChartPropsBase> = ({
    applications, 
    productFamilies,
    psArchitects,
    securityChampions,
    devLeads,
    productSecurityPM,
    productManager,
    tags
    }) => {
        const {
            selectedNames,
            selectedScale,
            selectedDateRange,
            selectedNameType,
            appChanged,
            appFamilyChanged,
            scaleChanged,
            dateRangeChanged,
            psArchitectsChanged,
            securityChampionsChanged,
            devLeadsChanged,
            productSecurityPMChanged,
            productManagerChanged,
            tagsChanged,
        } = LineChartBase();

    const requestData = async () => {
        let request: LineChartRequest = {
            scale: selectedScale ?? JiraStatisticScale.Day,
            startDate: selectedDateRange.startDate ?? new Date(),
            endDate: selectedDateRange.endDate ?? new Date(),
            nameType: selectedNameType ?? JiraStatisticAppFamilyFilter.Application,
            selectedNames: selectedNames ?? []
        }
        
        const response = await axios.post('/jirastatistic/getCsv', request)

        const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, "JiraStatisticRaw.csv");
    }

    return (
        <div className='statistic-pane'>
            <div className='chart-title'>
                <h4>CSV Export</h4>
            </div>
            <div className='jira-statistic-filter-container'>
                <JiraStatisticFilter 
                    applications={applications}
                    psArchitects={psArchitects}
                    securityChampions={securityChampions}
                    devLeads={devLeads}
                    productSecurityPM={productSecurityPM}
                    productManager={productManager}
                    tags={tags}
    
                    applicationChangedCallback={appChanged}
                    psArchitectsCallback={psArchitectsChanged}
                    securityChampionsCallback={securityChampionsChanged}
                    devLeadsCallback={devLeadsChanged}
                    productSecurityPMCallback={productSecurityPMChanged}
                    productManagerCallback={productManagerChanged}
                    tagsCallback={tagsChanged}
    
                    productFamilies={productFamilies} 
                    productFamilyChangedCallback={appFamilyChanged}
                    scaleChangedCallback={scaleChanged}
                    dateRangeChangedCallback={dateRangeChanged}
    
                    defaultAppFamilyFilter={selectedNameType}
                    defaultStartDate={selectedDateRange.startDate}
                    defaultEndDate={selectedDateRange.endDate}
                    defaultScale={selectedScale}
                    showScale={false}
                    id="csvexport"/>
                
                <Button variant="primary" className='csv-button' onClick={requestData}>Export</Button>
            </div>
        </div>
    );
};

export default CsvExport;