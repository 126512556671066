import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import {VscError} from "react-icons/vsc";

import AnimatedShieldLoader from "../shared/AnimatedShieldLoader";
import { AuditLog } from "./AuditLog";
import React from "react";
import { AuditFilterDto } from "./AuditFilterDto";
import { Box, MenuItem, Paper, Select, SelectChangeEvent, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { validate as uuidValidate } from 'uuid';
import './AuditLog.scss';
import { Button } from "react-bootstrap";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import SectionCard from "../AssetRecords/SectionCard";
import { AuditEntryType } from "./AuditEntryType";
import { TabContext, TabPanel } from "@mui/lab";
import { useParams } from "react-router-dom";

const fetchData = async (startDate: Date, endDate: Date, userEmail:string, scopeId: string | null, entityId: string | null, canonicalId: string): Promise<AuditLog[]> => {
    let filter: AuditFilterDto = {
        startDate: startDate,
        endDate: endDate,
        userEmail: userEmail,
        scopeId: scopeId === '' ? null : scopeId,
        entityId: entityId === '' ? null : entityId,
        canonicalId: canonicalId
    }

    let response = await axios.post("/audit", filter);

    response.data.forEach((log: any) => {
        switch (log.logType) {
            case AuditEntryType[AuditEntryType.Update]:
                let info = JSON.parse(log.data);
                log.formattedMessage = `${info.Change.PropertyName} was changed from ${info.Change.OldValue} to ${info.Change.NewValue}`;
                break;
            case AuditEntryType[AuditEntryType.Veracode]:
                log.formattedMessage = "Veracode: " + log.eventMessage;
                break;
            case AuditEntryType[AuditEntryType.Create]:
                log.formattedMessage = "Entity was created";
                break;
            case AuditEntryType[AuditEntryType.Delete]:
                log.formattedMessage = "Entity was deleted";
                break;
            case AuditEntryType[AuditEntryType.Statistics]:
                log.formattedMessage = "Canonical ID update in statistic: " + log.eventMessage;
                break;
        }
    });
    return response.data
}

const fetchUsers = async (): Promise<string[]> => {
    let response = await axios.get("/UserInfo")

    var emails = response.data.map((user: any) => user.email)
    return emails;
}

export const AuditLogs: React.FC = () => {
    const {
        data,
        isLoading,
        isSuccess
    } = useQuery(["get-users"], fetchUsers);

    const params = useParams<{ id: string }>();
    const logTypesToDisplay = [AuditEntryType[AuditEntryType.Update], AuditEntryType[AuditEntryType.Veracode], AuditEntryType[AuditEntryType.Create], AuditEntryType[AuditEntryType.Delete], AuditEntryType[AuditEntryType.Statistics]];
    const currentDate = new Date();
    const initialStartDate = new Date(currentDate.setDate(currentDate.getDate() - 1));

    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(new Date());
    const [userEmail, setUserEmail] = useState<string>("_");
    const [scopeId, setScopeId] = useState<string | null>(null);
    const [entityId, setEntityId] = useState<string | null>(params.id ?? null);
    const [canonicalId, setCanonicalId] = useState<string>("");
    const [scopeValidationMessage, setScopeValidationMessage] = useState<string | undefined>(undefined);
    const [entityValidationMessage, setEntityValidationMessage] = useState<string | undefined>(undefined);

    const [logs, setLogs] = useState<AuditLog[]>([]);
    const [selectedTab, setSelectedTab] = useState(AuditEntryType.Update);
    const [isDataLoad, setIsDataLoad] = useState(false);

    useEffect(() => {
        if(params.id) {
            getData();
        }
    // eslint-disable-next-line
    }, []);

    const getData = () => {
        let scopeIsValid = scopeId && scopeId.length > 0 ? uuidValidate(scopeId) : true;
        setScopeValidationMessage(scopeIsValid ? undefined : "Invalid GUID");

        let entityIsValid = entityId && entityId.length > 0 ? uuidValidate(entityId) : true;
        setEntityValidationMessage(entityIsValid ? undefined : "Invalid GUID");

        if (!scopeIsValid || !entityIsValid) return;
        setIsDataLoad(true);
        let email = userEmail === "_" ? "" : userEmail;
        fetchData(startDate, endDate, email, scopeId, entityId, canonicalId).then(
            (data) => {
                setLogs(data);
                setIsDataLoad(false);
            });
    }

    const handleUserChange = (event: SelectChangeEvent) => {
        setUserEmail(event.target.value);
    }

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
    };

    const parseEmailToName = (email: string | null): string => {
        if (!email) return '';
        const namePart = email.split('@')[0];
        const parts = namePart.split(/[._]/).map(part =>
            part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
        );
        return parts.length >= 2 ? `${parts.slice(0, -1).join(' ')} ${parts.slice(-1)}` : parts[0] || '';
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    if (isLoading) {
        return <div id="loader">
            <AnimatedShieldLoader />
        </div>
    }

    if (isSuccess) {
        return (
            <>
            <div className="audit-logs">
                <div className="filter">
                    <SectionCard title="Asset Audit Logs">
                        <div className="info-grid" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "10px" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{
                                        color: "white",
                                        '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                        '.MuiSvgIcon-root ': {
                                        fill: "inherit !important",
                                        },
                                        label: {color: "gray"},
                                    }}
                                    className="date-picker"
                                    label="Start Date"
                                    value={dayjs(startDate.toISOString().split('T')[0])}
                                    onChange={(value) => setStartDate(dayjs(value).toDate())}/>
                                <DatePicker
                                    sx={{
                                        color: "white",
                                        '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                        '.MuiSvgIcon-root ': {
                                        fill: "inherit !important",
                                        },
                                        label: {color: "gray"},
                                    }}
                                    className="date-picker"
                                    label="End Date"
                                    value={dayjs(endDate.toISOString().split('T')[0])}
                                    onChange={(value) => setEndDate(dayjs(value).toDate())}/>
                            </LocalizationProvider>
                            <Select
                                size="small"
                                sx={{
                                    color: "white",
                                    '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                    }
                                }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={userEmail}
                                    label="Users"
                                    onChange={handleUserChange}
                                >
                                    <MenuItem value={"_"}>All Users</MenuItem>
                                    {data?.map((email: string) => <MenuItem key={email} value={email}>{parseEmailToName(email)}</MenuItem>)}
                            </Select>
                            <TextField label="Scope ID" variant="outlined"
                                onChange={(e) => setScopeId(e.target.value)}
                                error={scopeValidationMessage !== undefined}
                                helperText={scopeValidationMessage}
                                sx={{
                                    color: "white",
                                    '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                    },
                                    label: {color: "gray"},
                                }}
                            />
                            <TextField label="Entity ID" variant="outlined"
                                onChange={(e) => setEntityId(e.target.value)}
                                error={entityValidationMessage !== undefined}
                                helperText={entityValidationMessage}
                                sx={{
                                    color: "white",
                                    '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                    },
                                    label: {color: "gray"},
                                }}
                                value={entityId ?? ""}
                            />
                            <TextField label="Canonical ID" variant="outlined"
                                onChange={(e) => setCanonicalId(e.target.value)}
                                sx={{
                                    color: "white",
                                    '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                    },
                                    '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                    },
                                    label: {color: "gray"},
                                }}
                            />
                            <Button style={{gridColumn: "3"}} onClick={getData}>Load data</Button>
                        </div>
                    </SectionCard>
                </div>

                <Box sx={{ width: '100%' }}>
                    <TabContext value={selectedTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs textColor="inherit" value={selectedTab} onChange={handleTabChange} aria-label="action-tabs">
                                <Tab label="Changes" value={AuditEntryType.Update}  />
                                <Tab label="Veracode Sync" value={AuditEntryType.Veracode}  />
                                <Tab label="Create" value={AuditEntryType.Create}  />
                                <Tab label="Delete" value={AuditEntryType.Delete}  />
                                <Tab label="Statistics Sync" value={AuditEntryType.Statistics}  />
                                <Tab label="All" value={'all'}  />
                            </Tabs>
                        </Box>
                        {isDataLoad === true && 
                        <div id="table-loader">
                            <AnimatedShieldLoader />
                        </div>}
                        {isDataLoad === false && <>
                        <TabPanel value={AuditEntryType.Update}>
                            <TableContainer component={Paper} className="application-table-paper">
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>User</TableCell>
                                            <TableCell>Scope</TableCell>
                                            <TableCell>Entity Id</TableCell>
                                            <TableCell>Message</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {logs.filter(x => AuditEntryType[AuditEntryType[x.logType]] === AuditEntryType[AuditEntryType.Update]).map((x, index) => 
                                        <TableRow key={index}>
                                            <TableCell>{formatDate(x.logDate.toString())}</TableCell>
                                            <TableCell>{parseEmailToName(x.user)}</TableCell>
                                            <TableCell>{x.scopeId}</TableCell>
                                            <TableCell>{x.entityId}</TableCell>
                                            <TableCell>{x.formattedMessage}</TableCell>
                                        </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        <TabPanel value={AuditEntryType.Veracode}>
                            <TableContainer component={Paper} className="application-table-paper">
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>User</TableCell>
                                            <TableCell>Scope</TableCell>
                                            <TableCell>Entity Id</TableCell>
                                            <TableCell>Message</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {logs.filter(x => AuditEntryType[AuditEntryType[x.logType]] === AuditEntryType[AuditEntryType.Veracode]).map((x, index) => 
                                        <TableRow key={index}>
                                            <TableCell>{formatDate(x.logDate.toString())}</TableCell>
                                            <TableCell>{parseEmailToName(x.user)}</TableCell>
                                            <TableCell>{x.scopeId}</TableCell>
                                            <TableCell>{x.entityId}</TableCell>
                                            <TableCell>{x.formattedMessage}</TableCell>
                                        </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        <TabPanel value={AuditEntryType.Create}>
                            <TableContainer component={Paper} className="application-table-paper">
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>User</TableCell>
                                            <TableCell>Scope</TableCell>
                                            <TableCell>Entity Id</TableCell>
                                            <TableCell>Message</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {logs.filter(x => AuditEntryType[AuditEntryType[x.logType]] === AuditEntryType[AuditEntryType.Create]).map((x, index) => 
                                        <TableRow key={index}>
                                            <TableCell>{formatDate(x.logDate.toString())}</TableCell>
                                            <TableCell>{parseEmailToName(x.user)}</TableCell>
                                            <TableCell>{x.scopeId}</TableCell>
                                            <TableCell>{x.entityId}</TableCell>
                                            <TableCell>{x.formattedMessage}</TableCell>
                                        </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        <TabPanel value={AuditEntryType.Delete}>
                            <TableContainer component={Paper} className="application-table-paper">
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>User</TableCell>
                                            <TableCell>Scope</TableCell>
                                            <TableCell>Entity Id</TableCell>
                                            <TableCell>Message</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {logs.filter(x => AuditEntryType[AuditEntryType[x.logType]] === AuditEntryType[AuditEntryType.Delete]).map((x, index) => 
                                        <TableRow key={index}>
                                            <TableCell>{formatDate(x.logDate.toString())}</TableCell>
                                            <TableCell>{parseEmailToName(x.user)}</TableCell>
                                            <TableCell>{x.scopeId}</TableCell>
                                            <TableCell>{x.entityId}</TableCell>
                                            <TableCell>{x.formattedMessage}</TableCell>
                                        </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        <TabPanel value={AuditEntryType.Statistics}>
                            <TableContainer component={Paper} className="application-table-paper">
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>User</TableCell>
                                            <TableCell>Scope</TableCell>
                                            <TableCell>Entity Id</TableCell>
                                            <TableCell>Message</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {logs.filter(x => AuditEntryType[AuditEntryType[x.logType]] === AuditEntryType[AuditEntryType.Statistics]).map((x, index) => 
                                        <TableRow key={index}>
                                            <TableCell>{formatDate(x.logDate.toString())}</TableCell>
                                            <TableCell>{parseEmailToName(x.user)}</TableCell>
                                            <TableCell>{x.scopeId}</TableCell>
                                            <TableCell>{x.entityId}</TableCell>
                                            <TableCell>{x.formattedMessage}</TableCell>
                                        </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        <TabPanel value={'all'}>
                            <TableContainer component={Paper} className="application-table-paper">
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>User</TableCell>
                                            <TableCell>Scope</TableCell>
                                            <TableCell>Entity Id</TableCell>
                                            <TableCell>Message</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {logs.filter(x => logTypesToDisplay.includes(AuditEntryType[AuditEntryType[x.logType]])).map((x, index) => 
                                        <TableRow key={index}>
                                            <TableCell>{formatDate(x.logDate.toString())}</TableCell>
                                            <TableCell>{parseEmailToName(x.user)}</TableCell>
                                            <TableCell>{x.scopeId}</TableCell>
                                            <TableCell>{x.entityId}</TableCell>
                                            <TableCell>{x.formattedMessage}</TableCell>
                                        </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        </>}
                    </TabContext>
                </Box>
            </div>
            </>
        )
    }

    return <><VscError /> Failed to load, please, check console</>
}
