import React, { useMemo } from 'react';
import { FaShieldAlt } from 'react-icons/fa';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { motion } from 'framer-motion';
import { LoadingCardContent } from '../shared/LoadingCardContent';
import { AnimatedNumber } from '../shared/AnimatedNumber';

interface ThreatModel {
    security_relevance_score: number;
}

interface ThreatModelCardProps {
    threatModels: ThreatModel[];
    isLoading: boolean;
}

const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
};

const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 }
};

const AnimatedCard = ({ children }: { children: React.ReactNode }) => {
    return (
        <motion.div 
            className="stat-card"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.3 }}
        >
            {children}
        </motion.div>
    );
};

const AnimatedStatItem = ({ label, value, dotColor, index }: { 
    label: string;
    value: number;
    dotColor: string;
    index: number;
}) => {
    return (
        <motion.div 
            className="stat-item"
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.3, delay: index * 0.1 }}
        >
            <span className="type-indicator" style={{ backgroundColor: dotColor }}></span>
            <span>{value} {label}</span>
        </motion.div>
    );
};

const CardHeader = () => (
    <div className="card-header">
        <div className="title">
            <FaShieldAlt size={18} />
            <span>Threat Modeling</span>
        </div>
    </div>
);

export const ThreatModelCard: React.FC<ThreatModelCardProps> = ({ threatModels, isLoading }) => {
    const securityScoreStats = useMemo(() => {
        if (!threatModels?.length) return { 
            total: 0, 
            scoreBelow70: 0, 
            scoreBetween70And80: 0, 
            scoreBetween80And90: 0, 
            scoreAbove90: 0 
        };
        
        const total = threatModels.length;
        const scoreBelow70 = threatModels.filter(model => model.security_relevance_score < 70).length;
        const scoreBetween70And80 = threatModels.filter(model => 
            model.security_relevance_score >= 70 && model.security_relevance_score < 80
        ).length;
        const scoreBetween80And90 = threatModels.filter(model => 
            model.security_relevance_score >= 80 && model.security_relevance_score < 90
        ).length;
        const scoreAbove90 = threatModels.filter(model => model.security_relevance_score >= 90).length;

        return { total, scoreBelow70, scoreBetween70And80, scoreBetween80And90, scoreAbove90 };
    }, [threatModels]);

    if (isLoading) {
        return (
            <AnimatedCard>
                <CardHeader />
                <LoadingCardContent />
            </AnimatedCard>
        );
    }

    const pieData = [
        { 
            name: '90-100', 
            value: securityScoreStats.scoreAbove90,
            color: '#FD4433'
        },
        { 
            name: '80-90', 
            value: securityScoreStats.scoreBetween80And90,
            color: '#FFA726'
        },
        { 
            name: '70-80', 
            value: securityScoreStats.scoreBetween70And80,
            color: '#FFCF44'
        },
        { 
            name: '<70', 
            value: securityScoreStats.scoreBelow70,
            color: '#fcda74'
        }
    ];

    return (
        <AnimatedCard>
            <CardHeader />
            <div className="card-content">
                <div className="main-stat">
                    <span className="number">
                        <AnimatedNumber value={securityScoreStats.total} />
                    </span>
                    <span className="label">Total Threat Models Created</span>
                    <div className="chart-container" style={{ height: '120px', marginTop: '1rem' }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie
                                    data={pieData}
                                    innerRadius={25}
                                    outerRadius={40}
                                    paddingAngle={2}
                                    dataKey="value"
                                >
                                    {pieData.map((entry, index) => (
                                        <Cell key={index} fill={entry.color} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="stat-breakdown">
                    <AnimatedStatItem
                        label="Relevance Score 90-100"
                        value={securityScoreStats.scoreAbove90}
                        dotColor="#FD4433"
                        index={0}
                    />
                    <AnimatedStatItem
                        label="Relevance Score 80-90"
                        value={securityScoreStats.scoreBetween80And90}
                        dotColor="#FFA726"
                        index={1}
                    />
                    <AnimatedStatItem
                        label="Relevance Score 70-80"
                        value={securityScoreStats.scoreBetween70And80}
                        dotColor="#FFCF44"
                        index={2}
                    />
                    <AnimatedStatItem
                        label="Relevance Score <70"
                        value={securityScoreStats.scoreBelow70}
                        dotColor="#fcda74"
                        index={3}
                    />
                </div>
            </div>
        </AnimatedCard>
    );
};

export default ThreatModelCard;