import axios from "axios";
import { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { Application } from "./Application";
import { ApplicationList } from "./ApplicationList";
import {VscError} from "react-icons/vsc";
import AnimatedShieldLoader from '../shared/AnimatedShieldLoader';

const fetchData = async (): Promise<Application[]> => {
    let response = await axios.get("/applications")
    console.log("apps:", response.data.applications)

    return response.data.applications
}

export const Applications: React.FC = () => {
    const {
        data,
        isLoading,
        isSuccess
    } = useQuery("applications", fetchData)

    const queryClient = useQueryClient()
    const location = useLocation()
    useEffect(() => {
        queryClient.invalidateQueries("applications")
    }, [location, queryClient])

    if (isLoading) {
        return <div id="loader">
            <AnimatedShieldLoader />
        </div>
    }

    if (isSuccess) {
        return (
            <>
                <ApplicationList items={data} />
            </>
        )
    }

    return <><VscError /> Failed to load, please, check console</>
}
