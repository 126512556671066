import React, { useState, useEffect } from 'react';
import { ThreeDots } from 'react-loader-spinner';

export const LoadingCardContent: React.FC = () => {
  const [color, setColor] = useState('#92F2CC');

  useEffect(() => {
    // Get the computed style
    const style = getComputedStyle(document.documentElement);
    const mainColor = style.getPropertyValue('--mainColor').trim();
    if (mainColor) {
      setColor(mainColor);
    }
  }, []);

  return (
    <div style={{ 
        position: 'relative',
        minHeight: '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        <ThreeDots 
            height={80}
            width={80}
            color={color}
        />
    </div>
  );
};