import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { VscError, VscInfo, VscSearch } from "react-icons/vsc";
import { MdOutlineFilterList } from "react-icons/md";
import AnimatedShieldLoader from '../shared/AnimatedShieldLoader';
import { SecretsScanner } from "./SecretsScannerList";
import "./SecretsScanner.scss";
import { useLocation } from "react-router-dom";

import SecretStats from './SecretStats';

interface Secret {
    secret_id: string;
    file_path: string;
    last_seen: string;
    line_number: number;
    repo_name: string;
    secret_type: string;
    default_branch: string;
    true_secret: boolean;
}

const fetchSecrets = async (repoName: string): Promise<Secret[]> => {
    if (!repoName) return [];
    try {
        console.log(`Fetching secrets for repo: ${repoName}`);
        const response = await axios.get(`/secrets-scanner/${repoName.toLowerCase()}`);
        console.log("API response:", response);
        return response.data;
    } catch (error) {
        console.error("Error fetching secrets:", error);
        throw error;
    }
}

const secretTypeOptions = [
    'All',
    'Secret Keyword',
    'JSON Web Token',
    'High Entropy String'
];

export const SecretsPage: React.FC = () => {
    const location = useLocation();
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [searchTrigger, setSearchTrigger] = useState<string>("");
    const [selectedSecretType, setSelectedSecretType] = useState<string>("All");
    const [ignoreFilter, setIgnoreFilter] = useState<string>("");
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [showFalsePositives, setShowFalsePositives] = useState(false);
    const filterRef = useRef<HTMLDivElement>(null);

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch
    } = useQuery(["secrets", searchTrigger], () => fetchSecrets(searchTrigger), {
        enabled: !!searchTrigger,
        retry: 1
    });

    const handleSearch = () => {
        if (searchQuery.trim()) {
            setSearchTrigger(searchQuery.trim());
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSearch();
        }
    };

    const filteredSecrets = data?.filter(secret => 
        (showFalsePositives || secret.true_secret) &&
        (selectedSecretType === 'All' || secret.secret_type.includes(selectedSecretType)) &&
        (!ignoreFilter || !secret.file_path.toLowerCase().includes(ignoreFilter.toLowerCase()))
    );

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (filterRef.current && !filterRef.current.contains(event.target as Node)) {
                setIsFilterOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const repoParam = searchParams.get('repo');
        if (repoParam) {
            setSearchQuery(repoParam);
            setSearchTrigger(repoParam);
        }
    }, [location]);

    return (
        <div className={`secrets-scanner ${!searchTrigger ? 'initial-state' : ''}`}>
            <div className="search-container">
                <div className="filter-container" ref={filterRef}>
                    <button 
                        className={`filter-button ${isFilterOpen ? 'active' : ''}`}
                        onClick={() => setIsFilterOpen(!isFilterOpen)}
                        aria-label="Filter secrets"
                    >
                        <MdOutlineFilterList />
                    </button>
                    {isFilterOpen && (
                        <div className="filter-popover">
                            <div className="filter-section">
                                <h3>Secret Type</h3>
                                {secretTypeOptions.map((option) => (
                                    <button
                                        key={option}
                                        className={`filter-option ${selectedSecretType === option ? 'selected' : ''}`}
                                        onClick={() => setSelectedSecretType(option)}
                                    >
                                        {option}
                                    </button>
                                ))}
                            </div>
                            <div className="filter-section">
                                <h3>Ignore File Paths</h3>
                                <input
                                    type="text"
                                    value={ignoreFilter}
                                    onChange={(e) => setIgnoreFilter(e.target.value)}
                                    placeholder="Enter word to ignore"
                                    className="ignore-filter-input"
                                />
                            </div>
                            <div className="filter-section">
                                <div className="checkbox-container">
                                    <input
                                        type="checkbox"
                                        id="showFalsePositives"
                                        checked={showFalsePositives}
                                        onChange={(e) => setShowFalsePositives(e.target.checked)}
                                    />
                                    <span>Show False Positives</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <input
                    type="text"
                    placeholder="Search for detected keys by repository name"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyPress={handleKeyPress}
                    className="search-input"
                />
                <button onClick={handleSearch} className="search-button" aria-label="Search">
                    <VscSearch />
                </button>
            </div>
            {!searchTrigger && <SecretStats />}
            {isLoading && (
                <div id="loader">
                    <AnimatedShieldLoader />
                </div>
            )}
            {isSuccess && filteredSecrets && filteredSecrets.length > 0 && 
                <SecretsScanner secrets={filteredSecrets} onRefresh={refetch} />
            }
            {isSuccess && (!filteredSecrets || filteredSecrets.length === 0) && searchTrigger && (
                <p className="no-results">
                    <VscInfo /> No secrets found for the repository "{searchTrigger}". 
                    This could mean either the repository is clean or it doesn't exist in our database.
                </p>
            )}
            {isError && (
                <p className="error">
                    <VscError /> Failed to load secrets. Error: {(error as Error).message}
                </p>
            )}
        </div>
    );
}

export default SecretsPage;