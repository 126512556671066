import React, { useState, useEffect, useCallback } from 'react';
import { BsPieChart } from 'react-icons/bs';
import { MdOutlineFilterList } from "react-icons/md";
import axios from 'axios';
import { DynamicChart } from './DynamicChart';
import { ChartFilters } from './ChartFilters';
import FilterTags from './FilterTags';
import { TimeGranularity, RatioData, ChartMetricUpdate, VULNERABILITY_COLORS } from './types';
import { MultiselectOption } from '../shared/Multiselect/MultiselectOption';
import './ReportingDashboard.scss';

interface DynamicRatioChartProps {
    // LineChartBase props
    selectedNames?: string[];
    selectedScale: string;
    selectedDateRange: { startDate: Date; endDate: Date };
    selectedNameType: string;
    appChanged: (selected: MultiselectOption[]) => Promise<void>;
    appFamilyChanged: (selected: MultiselectOption[]) => Promise<void>;
    psArchitectsChanged: (selected: MultiselectOption[]) => Promise<void>;
    securityChampionsChanged: (selected: MultiselectOption[]) => Promise<void>;
    devLeadsChanged: (selected: MultiselectOption[]) => Promise<void>;
    productSecurityPMChanged: (selected: MultiselectOption[]) => Promise<void>;
    productManagerChanged: (selected: MultiselectOption[]) => Promise<void>;
    tagsChanged: (selected: MultiselectOption[]) => Promise<void>;
    scaleChanged: (scale: string) => Promise<void>;
    dateRangeChanged: (startDate: Date, endDate: Date) => Promise<void>;

    // Data props
    applications: MultiselectOption[];
    productFamilies: MultiselectOption[];
    psArchitects: MultiselectOption[];
    securityChampions: MultiselectOption[];
    devLeads: MultiselectOption[];
    productSecurityPM: MultiselectOption[];
    productManager: MultiselectOption[];
    tags: MultiselectOption[];

    // Callback props
    onDataUpdate?: (update: ChartMetricUpdate) => void;
}

const processRatioData = (data: any[]): RatioData[] => {
    return data
        .map(item => ({
            timeStamp: item.timeStamp,
            sast: Number((item.sast || 0).toFixed(2)),
            dast: Number((item.dast || 0).toFixed(2)),
            sca: Number((item.sca || 0).toFixed(2)),
            PENTEST: Number((item.PENTEST || 0).toFixed(2)),
            bugBounty: Number((item.bugBounty || 0).toFixed(2))
        }))
        .sort((a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime());
};

export const DynamicRatioChart: React.FC<DynamicRatioChartProps> = ({
    selectedNames,
    selectedScale,
    selectedDateRange,
    selectedNameType,
    applications,
    productFamilies,
    psArchitects,
    securityChampions,
    devLeads,
    productSecurityPM,
    productManager,
    tags,
    appChanged,
    appFamilyChanged,
    psArchitectsChanged,
    securityChampionsChanged,
    devLeadsChanged,
    productSecurityPMChanged,
    productManagerChanged,
    tagsChanged,
    scaleChanged,
    dateRangeChanged,
    onDataUpdate
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<RatioData[]>([]);
    const [activeFilter, setActiveFilter] = useState(false);

    const fetchData = useCallback(async () => {
        if (!selectedNames?.length) return;

        setIsLoading(true);
        try {
            const response = await axios.post('/jirastatistic/getRatioOfIntroducedResolved', {
                scale: selectedScale,
                startDate: selectedDateRange.startDate?.toISOString(),
                endDate: selectedDateRange.endDate?.toISOString(),
                nameType: selectedNameType,
                selectedNames
            });

            const processedData = processRatioData(response.data);
            setData(processedData);
            onDataUpdate?.({
                data: processedData,
                timeGranularity: selectedScale as TimeGranularity
            });
        } catch (error) {
            console.error('Error fetching ratio data:', error);
        } finally {
            setIsLoading(false);
        }
    }, [selectedNames, selectedScale, selectedDateRange?.startDate, selectedDateRange?.endDate, selectedNameType, onDataUpdate]);

    useEffect(() => {
        if (selectedNames?.length) {
            fetchData();
        }
    }, [selectedNames, selectedScale, selectedDateRange?.startDate, selectedDateRange?.endDate, selectedNameType, fetchData]);

    return (
        <div className="reporting-dashboard__chart-cell">
            <div className="chart-header">
                <div className="chart-title">
                    <BsPieChart size={18} />
                    <h5>Ratio Of Introduced/Resolved</h5>
                    <FilterTags
                        selectedFilters={{
                            scale: selectedScale,
                            dateRange: selectedDateRange,
                        }}
                    />
                </div>
                <button
                    className="filter-button"
                    onClick={() => setActiveFilter(!activeFilter)}
                >
                    <MdOutlineFilterList size={18} /> Filters
                </button>
            </div>
            <div className="chart-container">
                <DynamicChart
                    data={data}
                    config={{
                        dateRange: selectedDateRange,
                        chartType: 'line',
                        granularity: selectedScale as TimeGranularity,
                        nameType: selectedNameType,
                        selectedNames: selectedNames || []
                    }}
                    loading={isLoading}
                    dataKeys={['sast', 'dast', 'sca', 'PENTEST', 'bugBounty']}
                    keyLabels={{
                        sast: 'SAST',
                        dast: 'DAST',
                        sca: 'SCA',
                        PENTEST: 'PENTEST',
                        bugBounty: 'Bug Bounty'
                    }}
                    colors={[
                        VULNERABILITY_COLORS.SAST.open,
                        VULNERABILITY_COLORS.DAST.open,
                        VULNERABILITY_COLORS.SCA.open,
                        VULNERABILITY_COLORS.PENTEST.open,
                        VULNERABILITY_COLORS['Bug Bounty'].open
                    ]}
                />
            </div>
            <ChartFilters
                isOpen={activeFilter}
                onClose={() => setActiveFilter(false)}
                onApplyFilters={() => {
                    fetchData();
                    setActiveFilter(false);
                }}
                applications={applications}
                productFamilies={productFamilies}
                psArchitects={psArchitects}
                securityChampions={securityChampions}
                devLeads={devLeads}
                productSecurityPM={productSecurityPM}
                productManager={productManager}
                tags={tags}
                chartId="ratio"
                defaultValues={{
                    startDate: selectedDateRange.startDate,
                    endDate: selectedDateRange.endDate,
                    scale: selectedScale,
                    appFamilyFilter: selectedNameType
                }}
                callbacks={{
                    applicationChangedCallback: appChanged,
                    productFamilyChangedCallback: appFamilyChanged,
                    psArchitectsCallback: psArchitectsChanged,
                    securityChampionsCallback: securityChampionsChanged,
                    devLeadsCallback: devLeadsChanged,
                    productSecurityPMCallback: productSecurityPMChanged,
                    productManagerCallback: productManagerChanged,
                    tagsCallback: tagsChanged,
                    scaleChangedCallback: scaleChanged,
                    dateRangeChangedCallback: dateRangeChanged
                }}
            />
        </div>
    );
};

export default DynamicRatioChart;