import React, { useState, useEffect } from 'react';
import { Oval } from 'react-loader-spinner';
import { MdRefresh, MdCheck, MdError } from 'react-icons/md';

interface RegenerateFeedbackModalProps {
    isOpen: boolean;
    onClose: () => void;
    onRegenerate: (feedback: string) => void;
    onRegenerationStart: () => void;
}

const RegenerateFeedbackModal: React.FC<RegenerateFeedbackModalProps> = ({
    isOpen,
    onClose,
    onRegenerate,
    onRegenerationStart
}) => {
    const [feedback, setFeedback] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [validationMessage, setValidationMessage] = useState<string>('');

    const handleRegenerate = async () => {
        if (!feedback.trim()) {
            setValidationMessage('Feedback is required');
            return;
        }

        setValidationMessage('');
        setIsSubmitting(true);

        try {
            await onRegenerate(feedback);
            onRegenerationStart(); // Start the refresh cycle when regeneration is successful
            setShowSuccess(true);

            setTimeout(() => {
                setShowSuccess(false);
                setFeedback('');
                setIsSubmitting(false);
                onClose();
            }, 5000);
        } catch (error: any) {
            setValidationMessage(error.message || 'An error occurred while regenerating');
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        if (!isOpen) {
            setFeedback('');
            setShowSuccess(false);
            setValidationMessage('');
            setIsSubmitting(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (validationMessage) {
            const timeoutId = setTimeout(() => setValidationMessage(''), 10000);
            return () => clearTimeout(timeoutId);
        }
    }, [validationMessage]);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content regenerate-feedback-modal">
                <h3>
                    <MdRefresh className="icon" />
                    Regenerate Threat Model
                </h3>
                <div className="form-group">
                    <label htmlFor="feedback" className="required">
                        Provide additional feedback to consider during regeneration: <span className="required-indicator">*</span>
                    </label>
                    <textarea
                        id="feedback"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        placeholder="E.g., 'Consider additional authentication vulnerabilities' or 'Focus more on API security'"
                        disabled={isSubmitting || showSuccess}
                    />
                </div>

                {validationMessage && (
                    <div className="error-message">
                        <div>
                            <MdError /> {validationMessage}
                        </div>
                    </div>
                )}

                {showSuccess && (
                    <div className="success-message">
                        <MdCheck />
                        <span>
                            Regeneration request sent.
                        </span>
                    </div>
                )}

                <div className="button-group">
                    <button
                        type="button"
                        onClick={onClose}
                        className="cancel-btn"
                        disabled={isSubmitting}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        onClick={handleRegenerate}
                        className="submit-btn"
                        disabled={isSubmitting || showSuccess}
                    >
                        {isSubmitting ? (
                            <>
                                <Oval
                                    visible={true}
                                    height="16"
                                    width="16"
                                    color="#ffffff"
                                    secondaryColor="#ffffff"
                                    ariaLabel="loading"
                                />
                                Sending...
                            </>
                        ) : (
                            'Regenerate'
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RegenerateFeedbackModal;