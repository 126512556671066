import React, { useEffect, useState } from 'react';
import { LineChartRequest } from './LineChartRequest';
import { Legend, Tooltip, ResponsiveContainer, PieChart, Cell, Pie } from 'recharts';
import JiraStatisticFilter from '../shared/JiraStatisticFilter';
import axios from 'axios';
import AnimatedShieldLoader from '../shared/AnimatedShieldLoader';

import './VulnerabilityRemediationExpandedChart.scss';

import { JiraStatisticAppFamilyFilter, JiraStatisticScale } from '../shared/JiraStatisticFilterConstants';
import LineChartBase from './LineChartBase';
import { ReductionGoal } from './ReductionGoal';
import { ChartPropsBase } from './ChartPropsBase';

const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="tooltip-label">{payload[0].name}</p>
                <p style={{ color: payload[0].color }}>
                    {`${payload[0].value} weeks`}
                </p>
            </div>
        );
    }
    return null;
};

const CustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, name }: any) => {
   const RADIAN = Math.PI / 180;
   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
   const x = cx + radius * Math.cos(-midAngle * RADIAN);
   const y = cy + radius * Math.sin(-midAngle * RADIAN);

   return (
     <text 
       x={x} 
       y={y} 
       fill="white" 
       textAnchor="middle" 
       dominantBaseline="central"
     >
       {name}
     </text>
   );
};

const renderChart = (isLoading: boolean, chart: React.ReactNode, height = 400) => {
    if (isLoading) {
        return (
            <div className='chart-pair' style={{ height: `${height}px` }}>
                <div className='one-chart' style={{ position: 'relative', height: '100%' }}>
                    <div style={{ 
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <AnimatedShieldLoader />
                    </div>
                </div>
            </div>
        );
    }
    return chart;
};

const ReductionGoalChart: React.FC<ChartPropsBase> = ({
    applications,
    productFamilies,
    psArchitects,
    securityChampions,
    devLeads,
    productSecurityPM,
    productManager,
    tags
}) => {
    const defaultData: ReductionGoal[]= [];
    const [data, setData] = useState<ReductionGoal[]>(defaultData);
    const [isLoading, setIsLoading] = useState(false);
    const {
        selectedNames,
        selectedScale,
        selectedDateRange,
        selectedNameType,
        appChanged,
        appFamilyChanged,
        scaleChanged,
        dateRangeChanged,
        psArchitectsChanged,
        securityChampionsChanged,
        devLeadsChanged,
        productSecurityPMChanged,
        productManagerChanged,
        tagsChanged,
    } = LineChartBase();

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'violet'];

    const requestData = async () => {
        setIsLoading(true);
        try {
            let request: LineChartRequest = {
                scale: selectedScale ?? JiraStatisticScale.Week,
                startDate: selectedDateRange.startDate ?? new Date(),
                endDate: selectedDateRange.endDate ?? new Date(),
                nameType: selectedNameType ?? JiraStatisticAppFamilyFilter.Application,
                selectedNames: selectedNames ?? []
            }
            
            const response = await axios.post('/jirastatistic/getReductionGoal', request)
            setData(response.data.map((x: ReductionGoal) => {
                x.weeksCount = Math.ceil(x.weeksCount);
                return x;
            }));
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if(selectedNames && selectedNames.length > 0){
            requestData();
        }
        // eslint-disable-next-line
    }, [selectedNames, selectedScale, selectedDateRange, selectedNameType]);

    return (
        <div className='statistic-pane'>
            <div className='chart-title'>
                <h4>Total Weeks Where 15% Reduction Goal Not Met</h4>
            </div>
            <JiraStatisticFilter 
                applications={applications}
                psArchitects={psArchitects}
                securityChampions={securityChampions}
                devLeads={devLeads}
                productSecurityPM={productSecurityPM}
                productManager={productManager}
                tags={tags}
                applicationChangedCallback={appChanged}
                psArchitectsCallback={psArchitectsChanged}
                securityChampionsCallback={securityChampionsChanged}
                devLeadsCallback={devLeadsChanged}
                productSecurityPMCallback={productSecurityPMChanged}
                productManagerCallback={productManagerChanged}
                tagsCallback={tagsChanged}
                productFamilies={productFamilies} 
                productFamilyChangedCallback={appFamilyChanged}
                scaleChangedCallback={scaleChanged}
                dateRangeChangedCallback={dateRangeChanged}
                defaultAppFamilyFilter={selectedNameType}
                defaultStartDate={selectedDateRange.startDate}
                defaultEndDate={selectedDateRange.endDate}
                defaultScale={selectedScale}
                showScale={false}
                id="reduction"
            />
            
            <div className='chart'>
                {renderChart(isLoading,
                    data.length === 0 ? (
                        <label className='text-info'>No data available</label>
                    ) : (
                        <div className='chart-pair'>
                            <div className='one-chart'>
                                <ResponsiveContainer height={400} width="100%">
                                    <PieChart>
                                        <Pie
                                            data={data}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            nameKey="scanType"
                                            label={CustomLabel}
                                            outerRadius={150}
                                            fill="#8884d8"
                                            dataKey="weeksCount"
                                        >
                                            {data.map((entry, index) => (
                                                <Cell 
                                                    key={`cell-${index}`} 
                                                    fill={COLORS[index % COLORS.length]} 
                                                />
                                            ))}
                                        </Pie>
                                        <Tooltip content={<CustomTooltip />} />
                                        <Legend 
                                            height={36}
                                            formatter={(value: string) => (
                                                <span style={{ color: '#666' }}>{value}</span>
                                            )}
                                        />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

export default ReductionGoalChart;