import React from 'react';
import { BsGraphUp } from "react-icons/bs";
import { 
    BarChart, 
    Bar,
    XAxis, 
    YAxis, 
    CartesianGrid, 
    Tooltip, 
    Legend, 
    ResponsiveContainer,
    Label 
} from 'recharts';
import { motion } from "framer-motion";
import { LoadingCardContent } from '../shared/LoadingCardContent';

interface CombinedDataPoint {
    timeStamp: string;
    [key: string]: string | number;
}

interface CustomTooltipProps {
    active?: boolean;
    payload?: Array<{
        name: string;
        value: number;
        color: string;
    }>;
    label?: string;
}

const COLORS = {
    'SAST': {
        open: '#B7ADD0',
        closed: '#B7ADD0'
    },
    'SCA': {
        open: '#1EB980',
        closed: '#1EB980'
    },
    'DAST': {
        open: '#6F48E3',
        closed: '#6F48E3'
    },
    'Pentest': {
        open: '#FFCF44',
        closed: '#FFCF44'
    },
    'Bug Bounty': {
        open: '#3B82F6',
        closed: '#3B82F6'
    }
};

const SCAN_TYPES = ['SAST', 'SCA', 'DAST', 'Pentest', 'Bug Bounty'];

const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
};

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="tooltip-label">{`Date: ${label}`}</p>
                {payload.map((entry, index) => (
                    <p key={index} style={{ color: entry.color }}>
                        {`${entry.name}: ${entry.value}`}
                    </p>
                ))}
            </div>
        );
    }
    return null;
};

interface VulnerabilitiesChartCardProps {
    data: CombinedDataPoint[];
    isLoading: boolean;
}

export const VulnerabilitiesChartCard: React.FC<VulnerabilitiesChartCardProps> = ({ data, isLoading }) => {
    const CardWrapper = ({ children }: { children: React.ReactNode }) => (
        <motion.div 
            className="stat-card"
            style={{ gridColumn: '1 / -1', minHeight: '450px' }}
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.3 }}
        >
            <div className="card-header">
                <div className="title">
                    <BsGraphUp size={18} />
                    <span>SECBUGs Weekly Overview</span>
                </div>
            </div>
            <div className="card-content" style={{ display: 'block' }}>
                {children}
            </div>
        </motion.div>
    );

    if (isLoading) {
        return (
            <CardWrapper>
                <LoadingCardContent />
            </CardWrapper>
        );
    }

    if (!data?.length) {
        return (
            <CardWrapper>
                <div className="flex items-center justify-center" style={{ height: '400px' }}>
                    <span className="text-gray-500">No data available</span>
                </div>
            </CardWrapper>
        );
    }

    return (
        <CardWrapper>
            <div style={{ height: '400px', width: '100%', display: 'flex', gap: '20px' }}>
                <div style={{ flex: 1 }}>
                    <ResponsiveContainer height={350}>
                        <BarChart
                            data={data}
                            margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
                            barSize={15}
                        >
                            <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                            <XAxis 
                                dataKey="timeStamp"
                                interval={0}
                                angle={-45}
                                textAnchor="end"
                                height={60}
                            />
                            <YAxis>
                                <Label 
                                    angle={-90}
                                    position="insideLeft"
                                    style={{ textAnchor: 'middle' }}
                                    offset={-6}
                                >
                                    Open Issues
                                </Label>
                            </YAxis>
                            <Tooltip content={<CustomTooltip />} />
                            <Legend 
                                height={36}
                                wrapperStyle={{
                                    paddingTop: '20px'
                                }}
                            />
                            {SCAN_TYPES.map(scanType => (
                                <Bar
                                    key={`${scanType}-open`}
                                    dataKey={`${scanType}-open`}
                                    name={`${scanType}`}
                                    fill={COLORS[scanType].open}
                                />
                            ))}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                <div style={{ flex: 1 }}>
                    <ResponsiveContainer height={350}>
                        <BarChart
                            data={data}
                            margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
                            barSize={15}
                        >
                            <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                            <XAxis 
                                dataKey="timeStamp"
                                interval={0}
                                angle={-45}
                                textAnchor="end"
                                height={60}
                            />
                            <YAxis>
                                <Label 
                                    angle={-90}
                                    position="insideLeft"
                                    style={{ textAnchor: 'middle' }}
                                    offset={-6}
                                >
                                    Closed Issues
                                </Label>
                            </YAxis>
                            <Tooltip content={<CustomTooltip />} />
                            <Legend 
                                height={36}
                                wrapperStyle={{
                                    paddingTop: '20px'
                                }}
                            />
                            {SCAN_TYPES.map(scanType => (
                                <Bar
                                    key={`${scanType}-closed`}
                                    dataKey={`${scanType}-closed`}
                                    name={`${scanType}`}
                                    fill={COLORS[scanType].closed}
                                />
                            ))}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </CardWrapper>
    );
};