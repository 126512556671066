import React, { useState, useMemo, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TableSortLabel,
    IconButton,
    TextField,
    InputAdornment
} from '@mui/material';
import { useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import AnimatedShieldLoader from '../shared/AnimatedShieldLoader';
import {
    FaEye,
    FaPlus,
    FaThumbsUp,
    FaThumbsDown,
    FaMeh,
    FaChartBar,
    FaTrash,
    FaSearch
} from 'react-icons/fa';
import { RiCloseCircleFill } from "react-icons/ri";
import { useLocation } from 'react-router-dom';
import AddThreatModelModal from './AddThreatModelModal';
import ThreatModelDetails from './ThreatModelDetails';

import './ThreatModelingList.scss';

interface ThreatModel {
    exercise_type: string;
    jira_issue_id: string;
    issue_type: string;
    assignee: string;
    jira_release: string;
    jira_title: string;
    last_updated: string;
    priority: string;
    product_team: string;
    security_relevance_score: number;
    threat_model: string;
    requestor: string;
    feedback: 'Liked' | 'Disliked' | 'Neutral';
    [key: string]: any; // Threat Model versioning
}

type Order = 'asc' | 'desc';

const getFeedbackIcon = (feedback: string) => {
    switch (feedback) {
        case "Liked":
            return <FaThumbsUp size={15} />;
        case "Disliked":
            return <FaThumbsDown size={15} />;
        case "Neutral":
            return <FaMeh size={15} />;
        default:
            return <FaMeh size={15} />;
    }
};

const getIssueTypeLetter = (issueType: string | null) => {
    if (!issueType) return 'U';
    return issueType.charAt(0).toUpperCase();
};

const getIssueTypeBadgeClass = (issueType: string | null) => {
    if (!issueType) return 'Other';
    const type = issueType.toLowerCase();
    if (['task', 'bug', 'epic', 'story'].includes(type)) {
        return issueType;
    }
    return 'Other';
};

const getExerciseTypeDisplay = (exerciseType: string) => {
    if (!exerciseType) return '';
    return exerciseType === 'threat-modeling' ? 'TM' : 'ARB';
};

export const ThreatModelScanner: React.FC = () => {
    const [sortConfig, setSortConfig] = useState<{ field: keyof ThreatModel; direction: Order }>({
        field: 'jira_release',
        direction: 'asc'
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [selectedModel, setSelectedModel] = useState<ThreatModel | null>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const location = useLocation();
    const queryClient = useQueryClient();

    const { data: threatModels, isLoading, isError, error } = useQuery('threatModels', async () => {
        const response = await axios.get('/threat-modeling');
        return response.data;
    });

    const parseEmailToName = (email: string | null): string => {
        if (!email) return '';
        const namePart = email.split('@')[0];
        const parts = namePart.split(/[._-]/).map(part =>
            part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
        );
        return parts.length >= 2 ? `${parts.slice(0, -1).join(' ')} ${parts.slice(-1)}` : parts[0] || '';
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
    };

    useEffect(() => {
        setPage(0);
    }, [searchQuery]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const issueIds = searchParams.get('issues');
        if (issueIds) {
            setSearchQuery(issueIds);
        }
    }, [location]);

    const handleSort = (field: keyof ThreatModel) => {
        setSortConfig(prev => ({
            field,
            direction: prev.field === field && prev.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    const filteredAndSortedData = useMemo(() => {
        if (!threatModels) return [];

        const searchTerms = searchQuery.toLowerCase().split(',').map(term => term.trim());

        return [...threatModels]
            .filter(model =>
                searchTerms.some(term =>
                    model.jira_issue_id.toLowerCase().includes(term) ||
                    model.product_team.toLowerCase().includes(term) ||
                    model.jira_release.toLowerCase().includes(term) ||
                    model.jira_title.toLowerCase().includes(term) ||
                    model.priority.toLowerCase().includes(term)
                ) || !searchQuery
            )
            .sort((a, b) => {
                const aValue = String(a[sortConfig.field]);
                const bValue = String(b[sortConfig.field]);
                return sortConfig.direction === 'asc'
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue);
            });
    }, [threatModels, sortConfig, searchQuery]);

    const feedbackStats = useMemo(() => {
        if (!threatModels) return { liked: 0, disliked: 0, neutral: 0, total: 0 };
        const total = threatModels.length;
        const liked = threatModels.filter(model => model.feedback === 'Liked').length;
        const disliked = threatModels.filter(model => model.feedback === 'Disliked').length;
        const neutral = total - liked - disliked;
        return { liked, disliked, neutral, total };
    }, [threatModels]);

    const handleDelete = async (jiraIssueId: string, event: React.MouseEvent) => {
        event.stopPropagation();
        if (window.confirm('Are you sure you want to delete this threat model? This action cannot be undone.')) {
            try {
                await axios.delete(`/threat-modeling/${jiraIssueId}`);
                queryClient.invalidateQueries('threatModels');
            } catch (error) {
                console.error('Error deleting threat model:', error);
                alert('Failed to delete threat model. Please try again.');
            }
        }
    };

    if (isLoading) {
        return (
            <div className="application-table-container">
                <div id="loader">
                    <AnimatedShieldLoader />
                </div>
            </div>
        );
    }
    if (isError) return <div>Error: {(error as Error).message}</div>;

    return (
        <div className="application-table-container">
            <div className="application-table-search">
                <div className="feedback-stats">
                    <span title="Liked"><FaThumbsUp /> {feedbackStats.liked}</span>
                    <span title="Disliked"><FaThumbsDown /> {feedbackStats.disliked}</span>
                    <span title="Neutral"><FaMeh /> {feedbackStats.neutral}</span>
                    <span title="Total"><FaChartBar /> {feedbackStats.total}</span>
                </div>
                <button onClick={() => setIsAddModalOpen(true)} className="add-threat-model-btn">
                    <FaPlus /> New Threat Model
                </button>
                <TextField
                    placeholder="Search..."
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FaSearch className="search-icon" />
                            </InputAdornment>
                        ),
                        endAdornment: searchQuery && (
                            <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    onClick={() => setSearchQuery('')}
                                >
                                    <RiCloseCircleFill className="clear-icon" />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </div>

            <TableContainer component={Paper} className="application-table-paper">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'exercise_type'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('exercise_type')}
                                >
                                    Type
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'jira_release'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('jira_release')}
                                >
                                    Fix Version
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'jira_issue_id'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('jira_issue_id')}
                                >
                                    Jira Issue ID
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'jira_title'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('jira_title')}
                                >
                                    Jira Title
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'product_team'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('product_team')}
                                >
                                    Product Team
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'priority'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('priority')}
                                >
                                    Priority
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'requestor'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('requestor')}
                                >
                                    Requestor
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'last_updated'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('last_updated')}
                                >
                                    Last Updated
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'security_relevance_score'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('security_relevance_score')}
                                >
                                    Score
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'feedback'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('feedback')}
                                >
                                    Feedback
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredAndSortedData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow key={row.jira_issue_id} hover>
                                    <TableCell>{getExerciseTypeDisplay(row.exercise_type)}</TableCell>
                                    <TableCell>{row.jira_release}</TableCell>
                                    <TableCell>
                                        <div className="jira-id-container">
                                            <a
                                                href={`https://dayforce.atlassian.net/browse/${row.jira_issue_id}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="jira-link"
                                            >
                                                {row.jira_issue_id}
                                            </a>
                                            <div className="issue-type-badges">
                                                <div
                                                    className={`issue-type-badge ${getIssueTypeBadgeClass(row.issue_type)}`}
                                                    title={row.issue_type || 'Unknown'}
                                                >
                                                    {getIssueTypeLetter(row.issue_type)}
                                                </div>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className={`jira-title-cell ${row.jira_title.length > 50 ? 'scrollable' : ''}`}>
                                            {row.jira_title}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className={`jira-title-cell ${row.product_team.length > 40 ? 'scrollable' : ''}`}>
                                            {row.product_team}
                                        </div>
                                    </TableCell>
                                    <TableCell>{row.priority}</TableCell>
                                    <TableCell>{parseEmailToName(row.requestor)}</TableCell>
                                    <TableCell>{formatDate(row.last_updated)}</TableCell>
                                    <TableCell>{row.exercise_type === 'arb-review' ? 'N/A' : row.security_relevance_score}</TableCell>
                                    <TableCell>
                                        <div className={`feedback-status ${row.feedback}`}>
                                            {getFeedbackIcon(row.feedback)}
                                            {row.feedback}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="action-buttons">
                                            <IconButton
                                                size="small"
                                                onClick={() => setSelectedModel(row)}
                                                className="view-model-btn"
                                                title="View Details"
                                            >
                                                <FaEye />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                onClick={(e) => handleDelete(row.jira_issue_id, e)}
                                                className="delete-model-btn"
                                                title="Delete Threat Model"
                                            >
                                                <FaTrash />
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={filteredAndSortedData.length}
                page={page}
                onPageChange={(_, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                }}
                className="application-table-pagination"
            />

            {selectedModel && (
                <ThreatModelDetails
                    model={selectedModel}
                    onClose={() => setSelectedModel(null)}
                    onModelUpdate={(updatedModel) => {
                        setSelectedModel(updatedModel);
                        // Also update the data in the list
                        queryClient.setQueryData<ThreatModel[]>('threatModels', (old) => {
                            if (!old) return [];
                            return old.map(item =>
                                item.jira_issue_id === updatedModel.jira_issue_id ? updatedModel : item
                            );
                        });
                    }}
                    onRegenerateRequest={() => { }}
                />
            )}

            <AddThreatModelModal
                isOpen={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
            />
        </div>
    );
};

export default ThreatModelScanner;