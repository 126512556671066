import React, { useEffect, useState } from 'react';
import VulnerabilityRemediationExpandedChart from './VulnerabilityRemediationExpandedChart';
import './JiraStatistic.scss';
import axios from 'axios';
import RatioOfIntroducedResolvedChart from './RatioOfIntroducedResolvedChart';
import ReductionGoalChart from './ReductionGoalChart';
import IntroducedTicketsChart from './IntroducedTicketsChart';
import RemediateTimeChart from './RemediateTimeChart';
import CsvExport from './CsvExport';

interface JiraStatisticProps {
    // Add any props you need for the component here
}

const JiraStatistic: React.FC<JiraStatisticProps> = (/*{ /* Destructure props here  }*/) => {
    const [applications, setApplications] = useState([]);
    const [productFamilies, setProductFamilies] = useState([]);
    const [PsArchitects, setPsArchitects] = useState([]);
    const [SecurityChampions, setSecurityChampions] = useState([]);
    const [DevLeads, setDevLeads] = useState([]);
    const [ProductSecurityPM, setProductSecurityPM] = useState([]);
    const [ProductManager, setProductManager] = useState([]);
    const [Tags, setTags] = useState([]);

    useEffect(() => {
        axios.get('/assets/app-and-families').then(response => {
            setApplications(response.data.applicationNames);
            setProductFamilies(response.data.families);
            setPsArchitects(response.data.psArchitects);
            setSecurityChampions(response.data.securityChampions);
            setDevLeads(response.data.devLeads);
            setProductSecurityPM(response.data.productSecurityPM);
            setProductManager(response.data.productManager);
            setTags(response.data.tags);
        })
    }, []);

    return (
        <div>
            <form>
            <VulnerabilityRemediationExpandedChart
                applications={applications}
                productFamilies={productFamilies}
                psArchitects={PsArchitects}
                securityChampions={SecurityChampions}
                devLeads={DevLeads}
                productSecurityPM={ProductSecurityPM}
                productManager={ProductManager}
                tags={Tags}
            />
            </form>
            <br></br>
            <form>
            <RatioOfIntroducedResolvedChart
                applications={applications}
                productFamilies={productFamilies}
                psArchitects={PsArchitects}
                securityChampions={SecurityChampions}
                devLeads={DevLeads}
                productSecurityPM={ProductSecurityPM}
                productManager={ProductManager}
                tags={Tags}/>
            </form>
            <br></br>
            <form>
            <ReductionGoalChart  applications={applications}
                productFamilies={productFamilies}
                psArchitects={PsArchitects}
                securityChampions={SecurityChampions}
                devLeads={DevLeads}
                productSecurityPM={ProductSecurityPM}
                productManager={ProductManager}
                tags={Tags}/>
            </form>
            <br></br>
            <form>
            <IntroducedTicketsChart applications={applications}
                productFamilies={productFamilies}
                psArchitects={PsArchitects}
                securityChampions={SecurityChampions}
                devLeads={DevLeads}
                productSecurityPM={ProductSecurityPM}
                productManager={ProductManager}
                tags={Tags} />
            </form>
            <br></br>
            <form>
            <RemediateTimeChart applications={applications}
                productFamilies={productFamilies}
                psArchitects={PsArchitects}
                securityChampions={SecurityChampions}
                devLeads={DevLeads}
                productSecurityPM={ProductSecurityPM}
                productManager={ProductManager}
                tags={Tags} />
            </form>
            <br></br>
            <form>
            <CsvExport applications={applications}
                productFamilies={productFamilies}
                psArchitects={PsArchitects}
                securityChampions={SecurityChampions}
                devLeads={DevLeads}
                productSecurityPM={ProductSecurityPM}
                productManager={ProductManager}
                tags={Tags} />
            </form>
            <br></br>
        </div>
    );
};

export default JiraStatistic;