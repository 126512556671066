import React from 'react';
import { motion } from "framer-motion";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { RiGitRepositoryFill } from "react-icons/ri";
import { FaFile } from "react-icons/fa";
import { useQuery } from "react-query";
import axios from "axios";
import { LoadingCardContent } from '../shared/LoadingCardContent';
import { AnimatedNumber } from '../shared/AnimatedNumber';

interface Secret {
    secret_id: string;
    file_path: string;
    repo_name: string;
    true_secret: boolean;
}

const fetchInitialSecrets = async (): Promise<Secret[]> => {
    try {
        const repoName = '-';
        const response = await axios.get('/secrets-scanner/' + repoName.toLowerCase());
        return response.data;
    } catch (error) {
        console.error("Error fetching secrets:", error);
        return [];
    }
};

const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
};

const AnimatedStatItem: React.FC<{ 
    label: string;
    value: number;
    index: number;
}> = ({ label, value, index }) => {
    return (
        <motion.div 
            className="stat-item"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.3, delay: index * 0.1 }}
        >
            <span className="type-indicator"></span>
            <span>{value} {label}</span>
        </motion.div>
    );
};

interface StatCardProps {
    children: React.ReactNode;
    title: string;
    icon: React.ReactNode;
    isLoading?: boolean;
}

const StatCard = ({ children, title, icon, isLoading }: StatCardProps) => {
    const CardHeader = (
        <div className="card-header">
            <div className="title">
                {icon}
                <span>{title}</span>
            </div>
        </div>
    );

    return (
        <motion.div 
            className="stat-card"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.3 }}
        >
            {CardHeader}
            {isLoading ? <LoadingCardContent /> : <div className="card-content">{children}</div>}
        </motion.div>
    );
};

const SecretStats: React.FC = () => {
    const { data: secrets = [], isLoading } = useQuery('initialSecrets', fetchInitialSecrets);

    // Process repo statistics
    const repoStats = React.useMemo(() => {
        const stats = (secrets || [])
            .filter(secret => secret.true_secret)
            .reduce((acc, secret) => {
                acc[secret.repo_name] = (acc[secret.repo_name] || 0) + 1;
                return acc;
            }, {} as Record<string, number>);

        return Object.entries(stats)
            .sort(([, a], [, b]) => b - a)
            .slice(0, 5)
            .map(([name, value]) => ({ name, value }));
    }, [secrets]);

    // Process file extension statistics
    const extensionStats = React.useMemo(() => {
        const stats = (secrets || [])
            .filter(secret => secret.true_secret)
            .reduce((acc, secret) => {
                const ext = secret.file_path.split('.').pop()?.toLowerCase() || 'no-extension';
                acc[ext] = (acc[ext] || 0) + 1;
                return acc;
            }, {} as Record<string, number>);

        return Object.entries(stats)
            .sort(([, a], [, b]) => b - a)
            .slice(0, 5)
            .map(([name, value]) => ({ name, value }));
    }, [secrets]);

    const CHART_COLORS = ['#1EB980', '#FFCF44', '#60a5fa', '#6b7280', '#9061F9'];

    return (
        <div className="secrets-stats">
            <StatCard 
                title="Top 5 Repositories with Secrets Discovered"
                icon={<RiGitRepositoryFill />}
                isLoading={isLoading}
            >
                <div className="main-stat">
                    <span className="number">
                        <AnimatedNumber value={repoStats.reduce((sum, item) => sum + item.value, 0)} />
                    </span>
                    <span className="label">Secrets Discovered</span>
                    <div className="chart-container">
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie
                                    data={repoStats}
                                    innerRadius={25}
                                    outerRadius={40}
                                    paddingAngle={2}
                                    dataKey="value"
                                >
                                    {repoStats.map((_, index) => (
                                        <Cell 
                                            key={`cell-${index}`} 
                                            fill={CHART_COLORS[index % CHART_COLORS.length]} 
                                        />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="stat-breakdown">
                    {repoStats.map((repo, index) => (
                        <AnimatedStatItem
                            key={repo.name}
                            label={repo.name}
                            value={repo.value}
                            index={index}
                        />
                    ))}
                </div>
            </StatCard>

            <StatCard 
                title="Top 5 File Extensions with Secrets Discovered"
                icon={<FaFile />}
                isLoading={isLoading}
            >
                <div className="main-stat">
                    <span className="number">
                        <AnimatedNumber value={extensionStats.reduce((sum, item) => sum + item.value, 0)} />
                    </span>
                    <span className="label">Total Files</span>
                    <div className="chart-container">
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie
                                    data={extensionStats}
                                    innerRadius={25}
                                    outerRadius={40}
                                    paddingAngle={2}
                                    dataKey="value"
                                >
                                    {extensionStats.map((_, index) => (
                                        <Cell 
                                            key={`cell-${index}`} 
                                            fill={CHART_COLORS[index % CHART_COLORS.length]} 
                                        />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="stat-breakdown">
                    {extensionStats.map((ext, index) => (
                        <AnimatedStatItem
                            key={ext.name}
                            label={ext.name}
                            value={ext.value}
                            index={index}
                        />
                    ))}
                </div>
            </StatCard>
        </div>
    );
};

export default SecretStats;