import React from 'react';
import { AnimatedNumber } from '../shared/AnimatedNumber';
import { TimeGranularity } from './types';
import { SmallLoadingCardContent } from '../shared/SmallLoadingCardContent';

interface MetricCardProps {
    title: string;
    icon: React.ReactNode;
    value: number;
    previousValue: number;
    label: string;
    reverseColors?: boolean;
    timeGranularity: TimeGranularity | string;
    formatValue?: (value: number) => string;
    isLoading?: boolean;
}

export const MetricCard: React.FC<MetricCardProps> = ({
    title,
    icon,
    value,
    label,
    formatValue = (val) => val.toLocaleString(),
    isLoading = false
}) => {
    const cleanValue = Number(value.toString().replace(/[^0-9.-]+/g, ''));

    if (isLoading) {
        return (
            <div className="reporting-dashboard__metric-card">
                <div className="card-header">
                    {icon}
                    <span>{title}</span>
                </div>
                <div className="card-content">
                    <SmallLoadingCardContent />
                </div>
            </div>
        );
    }

    return (
        <div className="reporting-dashboard__metric-card">
            <div className="card-header">
                {icon}
                <span>{title}</span>
            </div>
            <div className="card-content">
                <div className="value">
                    <AnimatedNumber
                        value={cleanValue}
                        formatValue={formatValue}
                    />
                </div>
                <div className="label">{label}</div>
            </div>
        </div>
    );
};

export default MetricCard;