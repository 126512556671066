import React, { useEffect, useState } from 'react';
import { LineChartRequest } from './LineChartRequest';
import { IntroducedResolved, StatusCounts, VulnerabilityRemediationExpanded } from './VulnerabilityRemediationExpanded';
import { CartesianGrid, Label, Legend, Line, LineChart, Tooltip, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import JiraStatisticFilter from '../shared/JiraStatisticFilter';
import axios from 'axios';
import AnimatedShieldLoader from '../shared/AnimatedShieldLoader';

import './VulnerabilityRemediationExpandedChart.scss';
import { JiraStatisticAppFamilyFilter, JiraStatisticScale } from '../shared/JiraStatisticFilterConstants';
import LineChartBase from './LineChartBase';
import { ChartPropsBase } from './ChartPropsBase';

const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="tooltip-label">{`Date: ${label}`}</p>
                {payload.map((entry: any, index: number) => (
                    <p key={index} style={{ color: entry.color }}>
                        {`${entry.name}: ${entry.value}`}
                    </p>
                ))}
            </div>
        );
    }
    return null;
};

const renderChart = (isLoading: boolean, chart: React.ReactNode, height = 400) => {
    if (isLoading) {
        return (
            <div className='chart-pair' style={{ height: `${height}px` }}>
                <div className='one-chart' style={{ position: 'relative', height: '100%' }}>
                    <div style={{ 
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <AnimatedShieldLoader />
                    </div>
                </div>
            </div>
        );
    }
    return chart;
};

const VulnerabilityRemediationExpandedChart: React.FC<ChartPropsBase> = ({ 
    applications, 
    productFamilies,
    psArchitects,
    securityChampions,
    devLeads,
    productSecurityPM,
    productManager,
    tags
}) => {
    const defaultData: VulnerabilityRemediationExpanded[] = [];
    const [data, setData] = useState<VulnerabilityRemediationExpanded[]>(defaultData);
    const [isLoading, setIsLoading] = useState(false);

    const {
        selectedNames,
        selectedScale,
        selectedDateRange,
        selectedNameType,
        appChanged,
        appFamilyChanged,
        scaleChanged,
        dateRangeChanged,
        psArchitectsChanged,
        securityChampionsChanged,
        devLeadsChanged,
        productSecurityPMChanged,
        productManagerChanged,
        tagsChanged,
        customizedAxisTick,
    } = LineChartBase();

    const requestData = async () => {
        setIsLoading(true);
        try {
            let request: LineChartRequest = {
                scale: selectedScale ?? JiraStatisticScale.Day,
                startDate: selectedDateRange.startDate ?? new Date(),
                endDate: selectedDateRange.endDate ?? new Date(),
                nameType: selectedNameType ?? JiraStatisticAppFamilyFilter.Application,
                selectedNames: selectedNames ?? []
            }
            
            const response = await axios.post('/jirastatistic/getVulnerabilityRemediationExpanded', request)
            const tmpData = response.data.map((x: VulnerabilityRemediationExpanded) => {
                x.statusCountsData.forEach((y: StatusCounts) => {
                    y.open = Math.ceil(y.open);
                    y.closed = Math.ceil(y.closed);
                    y.inProgress = Math.ceil(y.inProgress);
                    y.blocked = Math.ceil(y.blocked);
                    return y;
                });
                x.introducedResolvedData.forEach((y: IntroducedResolved) => {
                    y.introduced = Math.ceil(y.introduced);
                    y.resolved = Math.ceil(y.resolved);
                    return y;
                });
                return x;
            });
            setData(tmpData);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if(selectedNames && selectedNames.length > 0){
            requestData();
        }
        // eslint-disable-next-line
    }, [selectedNames, selectedScale, selectedDateRange, selectedNameType]);

    return (
        <div className="statistic-pane">
            <div className="chart-title">
                <h4>Vulnerability Remediation Progress (Expanded)</h4>
            </div>
            <JiraStatisticFilter 
                applications={applications}
                psArchitects={psArchitects}
                securityChampions={securityChampions}
                devLeads={devLeads}
                productSecurityPM={productSecurityPM}
                productManager={productManager}
                tags={tags}
                applicationChangedCallback={appChanged}
                psArchitectsCallback={psArchitectsChanged}
                securityChampionsCallback={securityChampionsChanged}
                devLeadsCallback={devLeadsChanged}
                productSecurityPMCallback={productSecurityPMChanged}
                productManagerCallback={productManagerChanged}
                tagsCallback={tagsChanged}
                productFamilies={productFamilies} 
                productFamilyChangedCallback={appFamilyChanged}
                scaleChangedCallback={scaleChanged}
                dateRangeChangedCallback={dateRangeChanged}
                defaultAppFamilyFilter={selectedNameType}
                defaultStartDate={selectedDateRange.startDate}
                defaultEndDate={selectedDateRange.endDate}
                defaultScale={selectedScale}
                showScale={true}
                id="vulnerability"
            />
            
            {data.length === 0 && !isLoading && 
                <div className="chart"><label className="text-info">No data available</label></div>
            }

            {data.length > 0 && data.map((item) => (
                <div className="chart" key={item.scanType}>
                    {renderChart(isLoading,
                        <div className="chart-container">
                            <div className="chart-header">
                                <h5>{item.scanType} - Status Counts</h5>
                            </div>
                            <ResponsiveContainer height={400} width="100%">
                                <LineChart 
                                    data={item.statusCountsData}
                                    margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend 
                                        height={36}
                                        wrapperStyle={{
                                            paddingTop: '20px'
                                        }}
                                    />
                                    <XAxis 
                                        dataKey="timeStamp" 
                                        padding="no-gap" 
                                        interval={0} 
                                        tick={customizedAxisTick}
                                        height={60}
                                    />
                                    <YAxis>
                                        <Label 
                                            angle={-90} 
                                            position="insideLeft"
                                            style={{ textAnchor: 'middle' }}
                                        >
                                            Issues count
                                        </Label>
                                    </YAxis>
                                    <Line 
                                        name="Open"
                                        strokeWidth={2} 
                                        type="monotone" 
                                        dataKey="open" 
                                        stroke="#fd4433" 
                                        dot={false}
                                        activeDot={{ r: 6 }}
                                    />
                                    <Line 
                                        name="Closed"
                                        strokeWidth={2} 
                                        type="monotone" 
                                        dataKey="closed" 
                                        stroke="#1EB980" 
                                        dot={false}
                                        activeDot={{ r: 6 }}
                                    />
                                    <Line 
                                        name="In Progress"
                                        strokeWidth={2} 
                                        type="monotone" 
                                        dataKey="inProgress" 
                                        stroke="#82ca9d" 
                                        dot={false}
                                        activeDot={{ r: 6 }}
                                    />
                                    <Line 
                                        name="Blocked"
                                        strokeWidth={2} 
                                        type="monotone" 
                                        dataKey="blocked" 
                                        stroke="orange" 
                                        dot={false}
                                        activeDot={{ r: 6 }}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default VulnerabilityRemediationExpandedChart;