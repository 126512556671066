export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export enum TimeGranularity {
  Hour = "hour",
  Day = "day",
  Week = "week",
  Month = "month",
  Year = "year",
}

export type ChartType = "line" | "bar" | "area" | "pie";
export type DateRangePreset = '30' | '90' | 'YTD';
export type ChartId =
  | "vulnerability"
  | "introduced"
  | "remediation"
  | "ratio"
  | "reduction";

export interface MetricValues {
  current: number;
  previous: number;
}

export interface ChartMetricUpdate {
  data: any[];
  timeGranularity: TimeGranularity;
}

export interface DashboardMetrics {
  openIssues: number;
  resolvedIssues: number;
  remediationTime: number;
  resolutionRatio: number;
}

export interface ChartConfig {
  dateRange: DateRange;
  chartType: ChartType;
  granularity: TimeGranularity;
  nameType: string;
  selectedNames: string[];
}

export interface VulnerabilityData {
  timeStamp: string;
  open: number;
  inProgress: number;
  blocked: number;
  ResolvedIssuesCount: number;
}

export interface IntroducedData {
  timeStamp: string;
  sast: number;
  dast: number;
  sca: number;
  PENTEST: number;
  bugBounty: number;
}

export interface RemediationData {
  timeStamp: string;
  criticalTime: number;
  highTime: number;
  mediumTime: number;
  lowTime: number;
}

export interface RatioData {
  timeStamp: string;
  sast: number;
  dast: number;
  sca: number;
  PENTEST: number;
  bugBounty: number;
}

export interface ReductionGoalData {
  scanType: string;
  weeksCount: number;
}

export interface DashboardData {
  vulnerability: VulnerabilityData[];
  introduced: IntroducedData[];
  remediation: RemediationData[];
  ratio: RatioData[];
  reduction: ReductionGoalData[];
}

export interface MetricCardProps {
  title: string;
  icon: React.ReactNode;
  value: number;
  label: string;
  reverseColors?: boolean;
  timeGranularity: TimeGranularity | string;
  formatValue?: (value: number) => string;
  isLoading?: boolean;
}

export interface DynamicChartProps {
  data: any[];
  config: ChartConfig;
  loading: boolean;
  dataKeys: string[];
  keyLabels?: { [key: string]: string };
  colors?: string[];
}

export const COLORS = {
  primary: "#1EB980",
  danger: "#FD4433",
  warning: "#FFCF44",
  info: "#3B82F6",
  success: "#10B981",
  purple: "#6F48E3",
};

export const VULNERABILITY_COLORS = {
  SAST: {
    open: "#B7ADD0",
  },
  SCA: {
    open: "#1EB980",
  },
  DAST: {
    open: "#6F48E3",
  },
  PENTEST: {
    open: "#FFCF44",
  },
  "Bug Bounty": {
    open: "#3B82F6",
  },
  "Secure SDLC": {
    open: "#FF8042",
  },
};

export const SEVERITY_COLORS = {
  critical: "#fe0101",
  high: "#ff4a02",
  medium: "#ff6f00",
  low: "#ff9500",
};
