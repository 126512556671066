export abstract class JiraStatisticAppFamilyFilter {
    static readonly Application = 'application';
    static readonly ProductFamily = 'family';
    static readonly PsArchitects = 'PsArchitects';
    static readonly SecurityChampions = 'SecurityChampions';
    static readonly DevLeads = 'DevLeads';
    static readonly ProductSecurityPM = 'ProductSecurityPM';
    static readonly ProductManager = 'ProductManager';
    static readonly Tags = 'Tags';
}

export abstract class JiraStatisticAppFamilyFilterLabel {
    static readonly application = 'Application';
    static readonly family = 'Product Family';
    static readonly PsArchitects = 'PS Architect';
    static readonly SecurityChampions = 'Security Champion';
    static readonly DevLeads = 'Dev Lead';
    static readonly ProductSecurityPM = 'Product Security PM';
    static readonly ProductManager = 'Product Manager';
    static readonly Tags = 'Tags';
}

export abstract class JiraStatisticScale {
    static readonly Day = 'day';
    static readonly Week = 'week';
    static readonly Month = 'month';
}