import { addDays } from "date-fns";
import { useState } from "react";
import { JiraStatisticScale, JiraStatisticAppFamilyFilter } from "../shared/JiraStatisticFilterConstants";
import { MultiselectOption } from "../shared/Multiselect/MultiselectOption";

export default function LineChartBase() {
    const [selectedNames, setSelectedNames] = useState<string[]>();
    const [selectedScale, setSelectedScale] = useState<string>(JiraStatisticScale.Day);
    const [selectedDateRange, setSelectedDateRange] = useState({ startDate: addDays(new Date(), -7), endDate: new Date() });
    const [selectedNameType, setSelectedNameType] = useState<string>(JiraStatisticAppFamilyFilter.ProductFamily);

    const appChanged = async (selected: MultiselectOption[]) => {
        setSelectedNameType(JiraStatisticAppFamilyFilter.Application);
        setSelectedNames(selected.map(x => x.value));
    }

    const appFamilyChanged = async (selected: MultiselectOption[]) => {
        setSelectedNameType(JiraStatisticAppFamilyFilter.ProductFamily);
        setSelectedNames(selected.map(x => x.value));
    }

    const psArchitectsChanged = async (selected: MultiselectOption[]) => {
        setSelectedNameType(JiraStatisticAppFamilyFilter.PsArchitects);
        setSelectedNames(selected.map(x => x.value));
    }

    const securityChampionsChanged = async (selected: MultiselectOption[]) => {
        setSelectedNameType(JiraStatisticAppFamilyFilter.SecurityChampions);
        setSelectedNames(selected.map(x => x.value));
    }

    const devLeadsChanged = async (selected: MultiselectOption[]) => {
        setSelectedNameType(JiraStatisticAppFamilyFilter.DevLeads);
        setSelectedNames(selected.map(x => x.value));
    }

    const productSecurityPMChanged = async (selected: MultiselectOption[]) => {
        setSelectedNameType(JiraStatisticAppFamilyFilter.ProductSecurityPM);
        setSelectedNames(selected.map(x => x.value));
    }

    const productManagerChanged = async (selected: MultiselectOption[]) => {
        setSelectedNameType(JiraStatisticAppFamilyFilter.ProductManager);
        setSelectedNames(selected.map(x => x.value));
    }

    const tagsChanged = async (selected: MultiselectOption[]) => {
        setSelectedNameType(JiraStatisticAppFamilyFilter.Tags);
        setSelectedNames(selected.map(x => x.value));
    }

    const scaleChanged = async (selected: string) => {
        setSelectedScale(selected);
    }

    const dateRangeChanged = async (startDate: Date, endDate: Date) => {
        setSelectedDateRange({ startDate, endDate });
    }

    const customizedAxisTick = (props) => {
        const {
            payload: { value },
            x, y
        } = props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                    {formatDate(value)}
                </text>
            </g>
        );
    };

    const formatDate = (input: string) => {
        let date = new Date(input);
        return date.toISOString().split('T')[0];
    }

    return {
        selectedNames,
        selectedScale,
        selectedDateRange,
        selectedNameType,
        appChanged,
        appFamilyChanged,
        scaleChanged,
        dateRangeChanged,
        psArchitectsChanged,
        securityChampionsChanged,
        devLeadsChanged,
        productSecurityPMChanged,
        productManagerChanged,
        tagsChanged,
        customizedAxisTick
    };
}