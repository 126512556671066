import React, { useState, useEffect, useCallback } from 'react';
import { BsExclamationTriangle, BsCheckCircle, BsClockHistory, BsPieChart } from 'react-icons/bs';
import axios from 'axios';
import { addDays } from "date-fns";
import { MetricCard } from './MetricCard';
import { DynamicVulnerabilityChart } from './DynamicVulnerabilityChart';
import { DynamicIntroducedChart } from './DynamicIntroducedChart';
import { DynamicRemediationChart } from './DynamicRemediationChart';
import { DynamicRatioChart } from './DynamicRatioChart';
import { DynamicReductionChart } from './DynamicReductionChart';
import { DashboardMetrics, ChartMetricUpdate, TimeGranularity, RatioData } from './types';
import { MultiselectOption } from '../shared/Multiselect/MultiselectOption';
import { JiraStatisticScale, JiraStatisticAppFamilyFilter } from '../shared/JiraStatisticFilterConstants';
import './ReportingDashboard.scss';

export const ReportingDashboard: React.FC = () => {
    // Filter options state
    const [applications, setApplications] = useState<MultiselectOption[]>([]);
    const [productFamilies, setProductFamilies] = useState<MultiselectOption[]>([]);
    const [psArchitects, setPsArchitects] = useState<MultiselectOption[]>([]);
    const [securityChampions, setSecurityChampions] = useState<MultiselectOption[]>([]);
    const [devLeads, setDevLeads] = useState<MultiselectOption[]>([]);
    const [productSecurityPM, setProductSecurityPM] = useState<MultiselectOption[]>([]);
    const [productManager, setProductManager] = useState<MultiselectOption[]>([]);
    const [tags, setTags] = useState<MultiselectOption[]>([]);

    // Metrics state
    const [metricLoading, setMetricLoading] = useState({
        openIssues: false,
        resolvedIssues: false,
        remediationTime: false,
        resolutionRatio: false
    });

    const [metrics, setMetrics] = useState<DashboardMetrics>({
        openIssues: 0,
        resolvedIssues: 0,
        remediationTime: 0,
        resolutionRatio: 0
    });

    const [metricsGranularity, setMetricsGranularity] = useState({
        vulnerability: TimeGranularity.Week,
        remediation: TimeGranularity.Week,
        ratio: TimeGranularity.Week
    });

    const [selectedNames, setSelectedNames] = useState<string[]>();
    const [selectedScale, setSelectedScale] = useState<string>(JiraStatisticScale.Week);
    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: addDays(new Date(), -30),
        endDate: new Date()
    });
    const [selectedNameType, setSelectedNameType] = useState<string>(JiraStatisticAppFamilyFilter.ProductFamily);

    // Filter change handlers
    const appChanged = useCallback(async (selected: MultiselectOption[]) => {
        setSelectedNameType(JiraStatisticAppFamilyFilter.Application);
        setSelectedNames(selected.map(x => x.value));
    }, []);

    const appFamilyChanged = useCallback(async (selected: MultiselectOption[]) => {
        setSelectedNameType(JiraStatisticAppFamilyFilter.ProductFamily);
        setSelectedNames(selected.map(x => x.value));
    }, []);

    const psArchitectsChanged = useCallback(async (selected: MultiselectOption[]) => {
        setSelectedNameType(JiraStatisticAppFamilyFilter.PsArchitects);
        setSelectedNames(selected.map(x => x.value));
    }, []);

    const securityChampionsChanged = useCallback(async (selected: MultiselectOption[]) => {
        setSelectedNameType(JiraStatisticAppFamilyFilter.SecurityChampions);
        setSelectedNames(selected.map(x => x.value));
    }, []);

    const devLeadsChanged = useCallback(async (selected: MultiselectOption[]) => {
        setSelectedNameType(JiraStatisticAppFamilyFilter.DevLeads);
        setSelectedNames(selected.map(x => x.value));
    }, []);

    const productSecurityPMChanged = useCallback(async (selected: MultiselectOption[]) => {
        setSelectedNameType(JiraStatisticAppFamilyFilter.ProductSecurityPM);
        setSelectedNames(selected.map(x => x.value));
    }, []);

    const productManagerChanged = useCallback(async (selected: MultiselectOption[]) => {
        setSelectedNameType(JiraStatisticAppFamilyFilter.ProductManager);
        setSelectedNames(selected.map(x => x.value));
    }, []);

    const tagsChanged = useCallback(async (selected: MultiselectOption[]) => {
        setSelectedNameType(JiraStatisticAppFamilyFilter.Tags);
        setSelectedNames(selected.map(x => x.value));
    }, []);

    const scaleChanged = useCallback(async (selected: string) => {
        setSelectedScale(selected);
    }, []);

    const dateRangeChanged = useCallback(async (startDate: Date, endDate: Date) => {
        setSelectedDateRange({ startDate, endDate });
    }, []);

    // Initial data fetch
    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                // Set loading state for all metrics
                setMetricLoading({
                    openIssues: true,
                    resolvedIssues: true,
                    remediationTime: true,
                    resolutionRatio: true
                });

                const response = await axios.get('/assets/app-and-families');

                // Set all the filter options
                setApplications(response.data.applicationNames);
                setProductFamilies(response.data.families);
                setPsArchitects(response.data.psArchitects);
                setSecurityChampions(response.data.securityChampions);
                setDevLeads(response.data.devLeads);
                setProductSecurityPM(response.data.productSecurityPM);
                setProductManager(response.data.productManager);
                setTags(response.data.tags);

                // Apply default filters - select all product families
                if (response.data.families?.length > 0) {
                    await appFamilyChanged(response.data.families);
                }

            } catch (error) {
                console.error('Error fetching initial data:', error);
                // Only clear loading state on error
                setMetricLoading({
                    openIssues: false,
                    resolvedIssues: false,
                    remediationTime: false,
                    resolutionRatio: false
                });
            }
        };

        fetchInitialData();
    }, [appFamilyChanged]);

    // Chart data update handlers
    const handleVulnerabilityDataUpdate = useCallback((update: ChartMetricUpdate) => {
        const { data, timeGranularity } = update;
        if (!data?.length) return;

        setMetricsGranularity(prev => ({
            ...prev,
            vulnerability: timeGranularity
        }));

        const latestData = data[data.length - 1];
        setMetrics(prev => ({
            ...prev,
            openIssues: latestData?.open || 0,
            resolvedIssues: latestData?.ResolvedIssuesCount || 0
        }));

        setMetricLoading(prev => ({
            ...prev,
            openIssues: false,
            resolvedIssues: false
        }));
    }, []);

    const handleRemediationDataUpdate = useCallback((update: ChartMetricUpdate) => {
        const { data, timeGranularity } = update;
        if (!data?.length) return;

        setMetricsGranularity(prev => ({
            ...prev,
            remediation: timeGranularity
        }));

        const latestData = data[data.length - 1];
        const averageTime = ((latestData?.criticalTime || 0) + (latestData?.highTime || 0)) / 2;

        setMetrics(prev => ({
            ...prev,
            remediationTime: averageTime
        }));

        setMetricLoading(prev => ({
            ...prev,
            remediationTime: false
        }));
    }, []);

    const handleRatioDataUpdate = useCallback((update: ChartMetricUpdate) => {
        const { data, timeGranularity } = update;
        if (!data?.length) return;

        setMetricsGranularity(prev => ({
            ...prev,
            ratio: timeGranularity
        }));

        const calculateAverageRatio = (period: RatioData[]) => {
            if (period.length === 0) return 0;

            const lastEntry = period[period.length - 1];
            const scanTypes = ['sast', 'dast', 'sca', 'PENTEST', 'bugBounty'];
            const validRatios = scanTypes
                .map(type => lastEntry[type as keyof RatioData] as number)
                .filter(ratio => ratio > 0 && !isNaN(ratio) && isFinite(ratio));

            if (validRatios.length === 0) return 0;

            return validRatios.reduce((sum, ratio) => sum + ratio, 0) / validRatios.length;
        };

        setMetrics(prev => ({
            ...prev,
            resolutionRatio: calculateAverageRatio(data)
        }));

        setMetricLoading(prev => ({
            ...prev,
            resolutionRatio: false
        }));
    }, []);

    // Props to be passed to all chart components
    const chartBaseProps = {
        selectedNames,
        selectedScale,
        selectedDateRange,
        selectedNameType,
        appChanged,
        appFamilyChanged,
        scaleChanged,
        dateRangeChanged,
        psArchitectsChanged,
        securityChampionsChanged,
        devLeadsChanged,
        productSecurityPMChanged,
        productManagerChanged,
        tagsChanged
    };

    const sharedProps = {
        applications,
        productFamilies,
        psArchitects,
        securityChampions,
        devLeads,
        productSecurityPM,
        productManager,
        tags,
        ...chartBaseProps
    };

    return (
        <div className="reporting-dashboard">
            <div className="reporting-dashboard__metrics-grid">
                <MetricCard
                    title="Open Issues"
                    icon={<BsExclamationTriangle size={18} />}
                    value={metrics.openIssues}
                    previousValue={0}
                    timeGranularity={metricsGranularity.vulnerability}
                    label="Current open issues"
                    isLoading={metricLoading.openIssues}
                />
                <MetricCard
                    title="Resolved Issues"
                    icon={<BsCheckCircle size={18} />}
                    value={metrics.resolvedIssues}
                    previousValue={0}
                    timeGranularity={metricsGranularity.vulnerability}
                    label="Total resolved"
                    isLoading={metricLoading.resolvedIssues}
                />
                <MetricCard
                    title="Average Remediation Time"
                    icon={<BsClockHistory size={18} />}
                    value={metrics.remediationTime}
                    previousValue={0}
                    timeGranularity={metricsGranularity.remediation}
                    label="Days per issue"
                    formatValue={(value) => Math.ceil(value).toString()}
                    isLoading={metricLoading.remediationTime}
                />
                <MetricCard
                    title="Resolution Ratio"
                    icon={<BsPieChart size={18} />}
                    value={metrics.resolutionRatio}
                    previousValue={0}
                    timeGranularity={metricsGranularity.ratio}
                    label="Resolved vs Introduced"
                    formatValue={(value) => value.toFixed(2)}
                    isLoading={metricLoading.resolutionRatio}
                />
            </div>

            <div className="reporting-dashboard__charts-grid">
                <DynamicVulnerabilityChart
                    onDataUpdate={handleVulnerabilityDataUpdate}
                    {...sharedProps}
                />
                <DynamicIntroducedChart {...sharedProps} />
                <DynamicRemediationChart
                    onDataUpdate={handleRemediationDataUpdate}
                    {...sharedProps}
                />
                <DynamicReductionChart {...sharedProps} />
                <DynamicRatioChart
                    onDataUpdate={handleRatioDataUpdate}
                    {...sharedProps}
                />
            </div>
        </div>
    );
};