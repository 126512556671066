import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';
import { FaPlus, FaCheck } from 'react-icons/fa';
import { MdError } from 'react-icons/md';

import "./NewAsset.scss";

interface AssetFormData {
  applicationName: string;
  assetName: string;
  canonicalId: string;
  productFamilyName: string;
  componentName: string;
  jiraProject: string;
  tags: string[];
  isDraft: boolean;
}

interface WizardProps {
  isOpen: boolean;
  onClose: () => void;
}

interface FormField {
  name: keyof AssetFormData;
  label: string;
  required: boolean;
  placeholder: string;
}

const STEPS = [
  { 
    id: 1, 
    title: 'Basic Info',
    fields: ['productFamilyName', 'applicationName', 'componentName']
  },
  { 
    id: 2, 
    title: 'Asset Details',
    fields: ['canonicalId', 'jiraProject']
  },
  { 
    id: 3, 
    title: 'Additional Info',
    fields: ['tags']
  }
] as const;

const FORM_FIELDS: Record<keyof Omit<AssetFormData, 'isDraft' | 'assetName'>, FormField> = {
  productFamilyName: {
    name: 'productFamilyName',
    label: 'Product Family Name',
    required: true,
    placeholder: 'Enter product family name'
  },
  applicationName: {
    name: 'applicationName',
    label: 'Asset/Application Name',
    required: true,
    placeholder: 'Enter asset name'
  },
  componentName: {
    name: 'componentName',
    label: 'Component Name',
    required: true,
    placeholder: 'Enter component name'
  },
  canonicalId: {
    name: 'canonicalId',
    label: 'Canonical ID',
    required: true,
    placeholder: 'Ex: XXXX-YYYYY-ZZZZZZ'
  },
  jiraProject: {
    name: 'jiraProject',
    label: 'Jira Destination',
    required: true,
    placeholder: 'Ex: PS'
  },
  tags: {
    name: 'tags',
    label: 'Tags',
    required: false,
    placeholder: 'Enter tags separated by commas'
  }
};

const FormLabel: React.FC<{ fieldName: string; isRequired: boolean }> = ({ 
  fieldName, 
  isRequired 
}) => (
  <label className={isRequired ? 'required' : ''}>
    {fieldName}
    {isRequired && <span className="required-indicator">*</span>}
  </label>
);

const NewAssetWizard: React.FC<WizardProps> = ({ isOpen, onClose }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState<Partial<AssetFormData>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationMessages, setValidationMessages] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  useEffect(() => {
    if (validationMessages.length > 0) {
      const timeoutId = setTimeout(() => setValidationMessages([]), 10000);
      return () => clearTimeout(timeoutId);
    }
  }, [validationMessages]);

  const validateStep = (stepNumber: number): string[] => {
    const currentFields = STEPS[stepNumber - 1].fields;
    const errors: string[] = [];

    currentFields.forEach(field => {
      const fieldConfig = FORM_FIELDS[field];
      if (fieldConfig?.required && !formData[field]) {
        errors.push(`${fieldConfig.label} is required`);
      }
    });

    return errors;
  };

  const handleInputChange = (field: keyof AssetFormData, value: string) => {
    setFormData(prev => ({
      ...prev,
      [field]: field === 'tags' ? value.split(',').map(tag => tag.trim()) : value,
      ...(field === 'componentName' ? { assetName: value } : {})
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors = validateStep(currentStep);
    
    if (errors.length > 0) {
      setValidationMessages(errors);
      return;
    }

    if (currentStep < STEPS.length) {
      setCurrentStep(prev => prev + 1);
      return;
    }

    setIsSubmitting(true);
    setValidationMessages([]);

    try {
      const response = await axios.post('/assets', {
        ...formData,
        isDraft: true
      });

      if (response.status === 200) {
        setSuccessMessage('New Asset Added Successfully');
        setTimeout(() => {
          navigate(`/assets/${response.data.assetId}`);
        }, 1000);
      }
    } catch (error: any) {
      setValidationMessages([
        error.response?.data?.message || 'An error occurred while creating the asset'
      ]);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderStepContent = (step: number) => {
    const currentFields = STEPS[step - 1].fields;

    return (
      <div className="step-content">
        <h2>
          {step === 1 && "Basic Info"}
          {step === 2 && "Asset Details"}
          {step === 3 && "Additional Info"}
        </h2>
        <p className="step-description">
          {step === 1 && "Enter core information about your asset"}
          {step === 2 && "Enter product family name and associated Jira project"}
          {step === 3 && "Enter extra information"}
        </p>

        {currentFields.map(fieldName => {
          const field = FORM_FIELDS[fieldName];
          return (
            <div key={fieldName} className="form-group">
              <FormLabel 
                fieldName={field.label} 
                isRequired={field.required} 
              />
              <textarea
                className="modern-input"
                value={Array.isArray(formData[fieldName]) 
                  ? (formData[fieldName] as string[]).join(', ')
                  : formData[fieldName] as string || ''}
                onChange={e => handleInputChange(fieldName, e.target.value)}
                placeholder={field.placeholder}
                disabled={isSubmitting}
              />
            </div>
          );
        })}
      </div>
    );
  };

  if (!isOpen) return null;

  return (
    <div className="add-application-modal-overlay">
      <div className="modal-content add-application-modal full-screen">
        <button className="close-btn" onClick={onClose}>×</button>
        
        <nav className="wizard-nav">
          <div className="wizard-header">
            <FaPlus className="icon" />
            <h1>Add New Asset</h1>
          </div>
          
          <div className="steps-indicator">
            {STEPS.map(step => (
              <div 
                key={step.id}
                className={`step ${currentStep >= step.id ? 'active' : ''}`}
              >
                <div className="step-number">
                  {currentStep > step.id ? <FaCheck /> : step.id}
                </div>
                <span>{step.title}</span>
              </div>
            ))}
          </div>
        </nav>

        <form onSubmit={handleSubmit}>
          <div className="wizard-content">
            {renderStepContent(currentStep)}

            {validationMessages.length > 0 && (
              <div className="error-message">
                {validationMessages.map((message, index) => (
                  <div key={index}>
                    <MdError /> {message}
                  </div>
                ))}
              </div>
            )}
            
            {successMessage && (
              <div className="success-message">
                <FaCheck /> {successMessage}
              </div>
            )}
          </div>

          <div className="button-group">
            <button 
              type="button" 
              onClick={onClose} 
              className="cancel-btn"
              disabled={isSubmitting}
            >
              Cancel
            </button>
            
            {currentStep > 1 && (
              <button 
                type="button"
                onClick={() => setCurrentStep(prev => prev - 1)}
                className="previous-btn"
                disabled={isSubmitting}
              >
                Previous
              </button>
            )}

            <button 
              type="submit" 
              className="submit-btn"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <Oval
                    visible={true}
                    height="16"
                    width="16"
                    color="#ffffff"
                    secondaryColor="#ffffff"
                    ariaLabel="loading"
                  />
                  Submitting...
                </>
              ) : currentStep === STEPS.length ? (
                'Save as Draft'
              ) : (
                'Next'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewAssetWizard;