import React, { useState, useMemo, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, TablePagination, TextField,
  TableSortLabel, IconButton, Menu, InputAdornment
} from '@mui/material';
import axios from 'axios';
import { NavLink, useNavigate } from "react-router-dom";
import { AssetRecord } from './AssetRecord';
import { FaPlus, FaFilter, FaSearch } from 'react-icons/fa';
import { GiDeathStar } from "react-icons/gi";
import { FaGithub } from "react-icons/fa6";
import { SiJira } from "react-icons/si";
import { CiViewColumn } from "react-icons/ci";
import { RiCloseCircleFill } from "react-icons/ri";
import { BsShieldFillCheck, BsShieldFillX, BsShieldFillExclamation, BsShieldSlash, BsShieldFillMinus } from 'react-icons/bs';
import { MdStar, MdStarOutline, MdOutlineFilterList, MdOutlineFilterListOff, MdOutlineClose } from 'react-icons/md';
import { RiLockPasswordLine } from "react-icons/ri";
import './AssetRecords.scss';

import NewAssetModal from './NewAssetModal';

interface Props {
  items: AssetRecord[];
}

type SortConfig = {
  field: keyof AssetRecord | '';
  direction: 'asc' | 'desc';
};

type FilterState = {
  [key: string]: Set<string>;
};

interface ColumnConfig {
  field: keyof AssetRecord | 'actions';
  label: string;
  canHide: boolean;
}

const COLUMNS: ColumnConfig[] = [
  { field: 'canonicalId', label: 'Canonical Id', canHide: false },
  { field: 'productFamilyName', label: 'Product Family', canHide: true },
  { field: 'applicationName', label: 'Application', canHide: true },
  { field: 'componentName', label: 'Component', canHide: true },
  { field: 'psArchitects', label: 'ProdSec Architects', canHide: true },
  { field: 'productSecurityPM', label: 'ProdSec PM', canHide: true },
  { field: 'productManager', label: 'Product Manager', canHide: true },
  { field: 'devLeads', label: 'Dev Leads', canHide: true },
  { field: 'securityChampions', label: 'Security Champions', canHide: true },
  { field: 'jiraProject', label: 'Jira Project', canHide: true },
  { field: 'veracodeName', label: 'SAST & SCA Profile Name', canHide: true },
  { field: 'platformBase', label: 'Environment', canHide: true },
  { field: 'policyComplianceStatus', label: 'Policy Compliance', canHide: true },
  { field: 'lastCompletedScanDate', label: 'Last Scan', canHide: true },
  { field: 'actions', label: 'Actions', canHide: false }
];

const parseEmailToName = (email: string | null): string => {
  if (!email) return '';
  const namePart = email.split('@')[0];
  const parts = namePart.split(/[._]/).map(part =>
    part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
  );
  return parts.length >= 2 ? `${parts.slice(0, -1).join(' ')} ${parts.slice(-1)}` : parts[0] || '';
};

const formatNames = (emails: (string | null)[] | null): string => {
  if (!emails || !Array.isArray(emails)) return '';
  return emails.filter(email => email).map(email => {
    const name = parseEmailToName(email);
    return name.split('-').map(part => part.charAt(0).toUpperCase() + part.slice(1)).join('-');
  }).filter(name => name).join(', ');
};

export const AssetRecords: React.FC<Props> = ({ items: initialItems }) => {
  const [items, setItems] = useState(initialItems);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [searchQuery, setSearchQuery] = useState(() => localStorage.getItem('searchQuery') || '');
  const [sortConfig, setSortConfig] = useState<SortConfig>({ field: 'canonicalId', direction: 'asc' });
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [columnMenuAnchor, setColumnMenuAnchor] = useState<null | HTMLElement>(null);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState<null | HTMLElement>(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState<{ [key: string]: HTMLElement | null }>({});

  const [columnFilters, setColumnFilters] = useState<FilterState>(() => {
    const saved = localStorage.getItem('columnFilters');
    return saved ? JSON.parse(saved, (key, value) => {
      return value instanceof Object && value.hasOwnProperty('values')
        ? new Set(value.values)
        : value;
    }) : {};
  });

  const [visibleColumns, setVisibleColumns] = useState<Set<string>>(() => {
    const saved = localStorage.getItem('visibleColumns');
    return saved ? new Set(JSON.parse(saved)) : new Set(
      COLUMNS
        .filter(col => !col.canHide || !['devLeads', 'securityChampions', 'jiraProject'].includes(col.field))
        .map(col => col.field)
    );
  });

  const [activeFilters, setActiveFilters] = useState<Set<string>>(() => {
    const saved = localStorage.getItem('activeFilters');
    return saved ? new Set(JSON.parse(saved)) : new Set();
  });

  const getFilteredValues = (field: keyof AssetRecord, currentFilters: FilterState) => {
    const values = new Set<string>();
    values.add('Empty');
    const valuesArray: string[] = [];

    let itemsToProcess = [...items];

    // Apply existing filters from other columns
    Object.entries(currentFilters).forEach(([filterField, filterValues]) => {
      if (filterField !== field && filterValues.size > 0) {
        itemsToProcess = itemsToProcess.filter(item => {
          const value = item[filterField as keyof AssetRecord];
          const isEmpty = !value || (Array.isArray(value) && value.every(v => !v));

          if (filterValues.has('Empty') && isEmpty) return true;

          if (Array.isArray(value)) {
            return value.some(email => {
              const name = parseEmailToName(email);
              return Array.from(filterValues).some(filterValue =>
                filterValue !== 'Empty' && name === filterValue
              );
            });
          }

          if (filterField === 'lastCompletedScanDate' && value) {
            const date = new Date(String(value));
            return !isNaN(date.getTime()) && filterValues.has(date.toLocaleDateString());
          }

          return Array.from(filterValues).some(filterValue =>
            filterValue === 'Empty' ? isEmpty : String(value) === filterValue
          );
        });
      }
    });

    // Apply search if present
    if (searchQuery) {
      const searchLower = searchQuery.toLowerCase();
      itemsToProcess = itemsToProcess.filter(item =>
        item.canonicalId?.toLowerCase().includes(searchLower) ||
        item.productFamilyName?.toLowerCase().includes(searchLower) ||
        item.applicationName?.toLowerCase().includes(searchLower) ||
        item.componentName?.toLowerCase().includes(searchLower)
      );
    }

    // Collect values based on field type
    itemsToProcess.forEach(item => {
      const value = item[field];

      if (field === 'lastCompletedScanDate' && value) {
        const date = new Date(String(value));
        if (!isNaN(date.getTime())) {
          valuesArray.push(date.toLocaleDateString());
        }
      } else if (Array.isArray(value)) {
        value.filter(Boolean).forEach(email => {
          const name = parseEmailToName(email);
          if (name) valuesArray.push(name);
        });
      } else if (value) {
        valuesArray.push(String(value));
      }
    });

    // Sort based on field type
    valuesArray.sort((a, b) => {
      if (field === 'canonicalId') {
        const [aPrefix, aMiddle, aSuffix = ''] = a.split('-');
        const [bPrefix, bMiddle, bSuffix = ''] = b.split('-');
        return aPrefix.localeCompare(bPrefix) ||
          aMiddle.localeCompare(bMiddle) ||
          aSuffix.localeCompare(bSuffix);
      } else if (field === 'lastCompletedScanDate') {
        return new Date(a).getTime() - new Date(b).getTime();
      } else if (['psArchitects', 'productSecurityPM', 'devLeads', 'securityChampions'].includes(field)) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      } else if (field === 'policyComplianceStatus') {
        const statusOrder = {
          'PASSED': 1,
          'CONDITIONAL_PASS': 2,
          'DETERMINING': 3,
          'DID_NOT_PASS': 4,
          'NOT_ASSESSED': 5
        };
        return (statusOrder[a as keyof typeof statusOrder] || 6) - (statusOrder[b as keyof typeof statusOrder] || 6);
      } else {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      }
    });

    // Add sorted values to Set
    valuesArray.forEach(value => values.add(value));

    return Array.from(values);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchQuery(newValue);
    localStorage.setItem('searchQuery', newValue);
  };

  const handleSort = (field: keyof AssetRecord) => {
    setSortConfig(prev => ({
      field,
      direction: prev.field === field && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>, field: string) => {
    event.stopPropagation();
    setFilterAnchorEl(prev => ({
      ...prev,
      [field]: event.currentTarget
    }));
  };

  const clearColumnFilter = (event: React.MouseEvent<HTMLElement>, field: string) => {
    event.stopPropagation();
    setColumnFilters(prev => {
      const newFilters = { ...prev };
      delete newFilters[field];
      localStorage.setItem('columnFilters', JSON.stringify(newFilters, (key, value) => {
        return value instanceof Set ? { values: Array.from(value) } : value;
      }));
      return newFilters;
    });
  };

  const handleFilterClose = (field: string) => {
    setFilterAnchorEl(prev => ({
      ...prev,
      [field]: null
    }));
  };

  const toggleFilter = (field: string, value: string) => {
    setColumnFilters(prev => {
      const newFilters = { ...prev };
      if (!newFilters[field]) {
        newFilters[field] = new Set([value]);
      } else if (newFilters[field].has(value)) {
        newFilters[field].delete(value);
        if (newFilters[field].size === 0) {
          delete newFilters[field];
        }
      } else {
        newFilters[field].add(value);
      }
      localStorage.setItem('columnFilters', JSON.stringify(newFilters, (key, value) => {
        return value instanceof Set ? { values: Array.from(value) } : value;
      }));
      return newFilters;
    });
  };

  const toggleColumn = (field: string) => {
    setVisibleColumns(prev => {
      const newSet = new Set(prev);
      if (newSet.has(field)) {
        newSet.delete(field);
      } else {
        newSet.add(field);
      }
      localStorage.setItem('visibleColumns', JSON.stringify(Array.from(newSet)));
      return newSet;
    });
  };

  const toggleTypeFilter = (field: string) => {
    setActiveFilters(prev => {
      const newSet = new Set(prev);
      if (newSet.has(field)) {
        newSet.delete(field);
      } else {
        newSet.add(field);
      }
      localStorage.setItem('activeFilters', JSON.stringify(Array.from(newSet)));
      return newSet;
    });
  };

  const getPolicyComplianceIcon = (status: string) => {
    switch (status) {
      case "PASSED": return <BsShieldFillCheck size={15} />;
      case "DID_NOT_PASS": return <BsShieldFillX size={15} />;
      case "CONDITIONAL_PASS": return <BsShieldFillExclamation size={15} />;
      case "NOT_ASSESSED": return <BsShieldSlash size={15} />;
      case "DETERMINING": return <BsShieldFillMinus size={15} />;
      default: return null;
    }
  };

  const getScanDateColor = (scanDate: string) => {
    const currentDate = new Date();
    const lastCompletedScanDate = new Date(scanDate);
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);
    return lastCompletedScanDate < thirtyDaysAgo ? "#fd4433" : "#1EB980";
  };

  const addRemoveToFavorites = async (applicationId: string | undefined, assetId: string) => {
    try {
      await axios.post(`/favorites/${applicationId}`);
      const appResponse = await axios.get(`/assets/${assetId}`);
      setItems(prev => prev.map(item =>
        item.id === assetId ? appResponse.data : item
      ));
    } catch (error) {
      console.error('Error updating favorites:', error);
    }
  };

  const handleSecretsScanner = (repoUrl: string | undefined) => {
    if (!repoUrl) return;
    const repo = repoUrl.split('/').pop() || '';
    navigate(`/secrets-scanner?repo=${encodeURIComponent(repo)}`);
  };

  useEffect(() => {
    setPage(0);
  }, [searchQuery, activeFilters, columnFilters]);

  const renderFilterMenu = (field: string) => (
    <Menu
      anchorEl={filterAnchorEl[field]}
      open={Boolean(filterAnchorEl[field])}
      onClose={() => handleFilterClose(field)}
      PaperProps={{
        className: "column-menu-paper"
      }}
    >
      <div className="filter-section">
        <h3>Filter Values</h3>
        {getFilteredValues(field as keyof AssetRecord, columnFilters).map((value) => (
          <div key={value} className="checkbox-container">
            <input
              type="checkbox"
              id={`filter-${field}-${value}`}
              checked={columnFilters[field]?.has(value) || false}
              onChange={() => toggleFilter(field, value)}
            />
            <span>{value}</span>
          </div>
        ))}
      </div>
    </Menu>
  );

  const renderColumnHeader = (column: ColumnConfig) => (
    <div className="column-header">
      {column.field !== 'actions' ? (
        <>
          <TableSortLabel
            active={sortConfig.field === column.field}
            direction={sortConfig.direction}
            onClick={() => handleSort(column.field as keyof AssetRecord)}
          >
            {column.label}
          </TableSortLabel>
          <div className="filter-actions">
            <IconButton
              size="small"
              onClick={(e) => handleFilterClick(e, column.field)}
              className={`filter-icon ${columnFilters[column.field]?.size ? 'active' : ''}`}
            >
              <FaFilter size={12} />
            </IconButton>
            {columnFilters[column.field]?.size > 0 && (
              <IconButton
                size="small"
                onClick={(e) => clearColumnFilter(e, column.field)}
                className="clear-filter-icon"
                title="Clear filter"
              >
                <MdOutlineClose />
              </IconButton>
            )}
          </div>
          {renderFilterMenu(column.field)}
        </>
      ) : column.label}
    </div>
  );

  const filteredAndSortedData = useMemo(() => {
    let processed = [...items];

    // Apply column filters
    Object.entries(columnFilters).forEach(([field, values]) => {
      if (values.size > 0) {
        processed = processed.filter(item => {
          const value = item[field as keyof AssetRecord];
          const isEmpty = !value || (Array.isArray(value) && value.every(v => !v));

          if (values.has('Empty') && isEmpty) return true;

          if (Array.isArray(value)) {
            return value.some(email => {
              const name = parseEmailToName(email);
              return Array.from(values).some(filterValue =>
                filterValue !== 'Empty' && name === filterValue
              );
            });
          }

          if (field === 'lastCompletedScanDate' && value) {
            const date = new Date(String(value));
            return !isNaN(date.getTime()) && values.has(date.toLocaleDateString());
          }

          return Array.from(values).some(filterValue =>
            filterValue === 'Empty' ? isEmpty : String(value) === filterValue
          );
        });
      }
    });

    // Apply search filter
    if (searchQuery) {
      const searchLower = searchQuery.toLowerCase();
      processed = processed.filter(item =>
        item.canonicalId?.toLowerCase().includes(searchLower) ||
        item.productFamilyName?.toLowerCase().includes(searchLower) ||
        item.applicationName?.toLowerCase().includes(searchLower) ||
        item.componentName?.toLowerCase().includes(searchLower)
      );
    }

    // Apply type filters
    const typeFilters = ['isProduction', 'isFlagship', 'isDraft', 'nonProduction', 'importToJira', 'notImportToJira'].filter(f => activeFilters.has(f));
    if (typeFilters.length > 0) {
      processed = processed.filter(item =>
        typeFilters.some(filter => {
          switch (filter) {
            case 'nonProduction':
              return !item.isProduction;
            case 'notImportToJira':
              return !item.importToJira;
            default:
              return item[filter as keyof AssetRecord];
          }
        })
      );
    }

    // Apply sorting
    if (sortConfig.field) {
      processed.sort((a, b) => {
        if (a.isInFavorites !== b.isInFavorites) {
          return a.isInFavorites ? -1 : 1;
        }

        if (sortConfig.field === 'canonicalId') {
          const aValue = String(a[sortConfig.field] || '');
          const bValue = String(b[sortConfig.field] || '');
          const [aPrefix, aMiddle, aSuffix = ''] = (aValue || '').split('-');
          const [bPrefix, bMiddle, bSuffix = ''] = (bValue || '').split('-');

          const comparison = aPrefix.localeCompare(bPrefix) ||
            aMiddle.localeCompare(bMiddle) ||
            aSuffix.localeCompare(bSuffix);

          return sortConfig.direction === 'asc' ? comparison : -comparison;
        }

        if (sortConfig.field === 'psArchitects' || sortConfig.field === 'productSecurityPM' ||
          sortConfig.field === 'devLeads' || sortConfig.field === 'securityChampions') {
          const aValue = formatNames(a[sortConfig.field] as (string | null)[]);
          const bValue = formatNames(b[sortConfig.field] as (string | null)[]);
          return sortConfig.direction === 'asc'
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }

        const aValue = String(a[sortConfig.field] || '');
        const bValue = String(b[sortConfig.field] || '');
        return sortConfig.direction === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      });
    }

    return processed;
  }, [items, searchQuery, sortConfig, activeFilters, columnFilters]);

  return (
    <div className="application-table-container">
      <div className="application-table-search">
        <button onClick={() => setIsAddModalOpen(true)} className="add-threat-model-btn">
          <FaPlus /> Add New Asset
        </button>
        <button onClick={(e) => setColumnMenuAnchor(e.currentTarget)} className="add-threat-model-btn">
          <CiViewColumn /> Columns
        </button>
        <button onClick={(e) => setFilterMenuAnchor(e.currentTarget)} className="add-threat-model-btn">
          <MdOutlineFilterList /> Filters
        </button>
        <TextField
          placeholder="Search..."
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FaSearch className="search-icon" />
              </InputAdornment>
            ),
            endAdornment: searchQuery && (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => {
                    setSearchQuery('');
                    localStorage.removeItem('searchQuery');
                  }}
                >
                  <RiCloseCircleFill className="clear-icon" />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </div>

      <Menu
        anchorEl={columnMenuAnchor}
        open={Boolean(columnMenuAnchor)}
        onClose={() => setColumnMenuAnchor(null)}
        PaperProps={{
          className: "column-menu-paper"
        }}
      >
        <div className="filter-section">
          <h3>Columns</h3>
          {COLUMNS.filter(col => col.canHide).map((column) => (
            <div key={column.field} className="checkbox-container">
              <input
                type="checkbox"
                id={`col-${column.field}`}
                checked={visibleColumns.has(column.field)}
                onChange={() => toggleColumn(column.field)}
              />
              <span>{column.label}</span>
            </div>
          ))}
        </div>
      </Menu>

      <Menu
        anchorEl={filterMenuAnchor}
        open={Boolean(filterMenuAnchor)}
        onClose={() => setFilterMenuAnchor(null)}
        PaperProps={{
          className: "column-menu-paper"
        }}
      >
        <div className="filter-section">
          <h3>Asset Type</h3>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="filter-production"
              checked={activeFilters.has('isProduction')}
              onChange={() => toggleTypeFilter('isProduction')}
            />
            <span>Production</span>
          </div>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="filter-non-production"
              checked={activeFilters.has('nonProduction')}
              onChange={() => toggleTypeFilter('nonProduction')}
            />
            <span>Non-Production</span>
          </div>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="filter-flagship"
              checked={activeFilters.has('isFlagship')}
              onChange={() => toggleTypeFilter('isFlagship')}
            />
            <span>Flagship</span>
          </div>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="filter-imported"
              checked={activeFilters.has('importToJira')}
              onChange={() => toggleTypeFilter('importToJira')}
            />
            <span>Imported</span>
          </div>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="filter-not-imported"
              checked={activeFilters.has('notImportToJira')}
              onChange={() => toggleTypeFilter('notImportToJira')}
            />
            <span>Not Imported</span>
          </div>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="filter-draft"
              checked={activeFilters.has('isDraft')}
              onChange={() => toggleTypeFilter('isDraft')}
            />
            <span>Draft</span>
          </div>
        </div>

        <div className="clear-filters-section">
          <button
            onClick={() => {
              setSearchQuery('');
              setColumnFilters({});
              setActiveFilters(new Set());
              localStorage.removeItem('searchQuery');
              localStorage.removeItem('columnFilters');
              localStorage.removeItem('activeFilters');
              setFilterMenuAnchor(null);
            }}
            className="clear-all-btn"
            disabled={!searchQuery && Object.keys(columnFilters).length === 0 && activeFilters.size === 0}
          >
            <MdOutlineFilterListOff />
            Clear All Filters
          </button>
        </div>
      </Menu>

      <TableContainer component={Paper} className="application-table-paper">
        <Table>
          <TableHead>
            <TableRow>
              {COLUMNS.map((column) => (
                visibleColumns.has(column.field) && (
                  <TableCell key={column.field}>
                    {renderColumnHeader(column)}
                  </TableCell>
                )
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAndSortedData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  key={row.id}
                  hover
                  style={{ position: 'relative' }}
                >
                  {COLUMNS.map((column) => (
                    visibleColumns.has(column.field) && (
                      <TableCell key={column.field}>
                        {column.field === 'canonicalId' && (
                          <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                            <div className={`canonical-id-container ${!row.importToJira ? 'disabled' : ''}`}>
                              <NavLink to={`/assets/${row.id}`} className="application-table-link">
                                {row.canonicalId}
                              </NavLink>
                            </div>
                            <div className="status-indicators">
                              {row.isProduction && <span className="status-dot production" title="Production">P</span>}
                              {row.isFlagship && <span className="status-dot flagship" title="Flagship">F</span>}
                              {row.isDraft && <span className="status-dot draft" title="Draft">D</span>}
                              {!row.importToJira && <span className="status-dot no-jira" title="Not Imported"><GiDeathStar /></span>}
                            </div>
                          </div>
                        )}
                        {column.field === 'productFamilyName' && row.productFamilyName}
                        {column.field === 'applicationName' && row.applicationName}
                        {column.field === 'componentName' && row.componentName}
                        {column.field === 'psArchitects' && formatNames(row.psArchitects)}
                        {column.field === 'productSecurityPM' && formatNames(row.productSecurityPM)}
                        {column.field === 'productManager' && formatNames(row.productManager)}
                        {column.field === 'devLeads' && formatNames(row.devLeads)}
                        {column.field === 'securityChampions' && formatNames(row.securityChampions)}
                        {column.field === 'jiraProject' && row.jiraProject}
                        {column.field === 'veracodeName' && row.veracodeName}
                        {column.field === 'platformBase' && row.platformBase}
                        {column.field === 'policyComplianceStatus' && (
                          <div className={`policyComplianceStatus ${row.policyComplianceStatus}`}>
                            {getPolicyComplianceIcon(row.policyComplianceStatus)}
                            {row.policyComplianceStatus}
                          </div>
                        )}
                        {column.field === 'lastCompletedScanDate' && row.applicationId && (
                          <span style={{ color: getScanDateColor(row.lastCompletedScanDate) }}>
                            {new Date(row.lastCompletedScanDate).toLocaleDateString()}
                          </span>
                        )}
                        {column.field === 'actions' && (
                          <>
                            {row.applicationId && (
                              <IconButton size="small" onClick={() => addRemoveToFavorites(row.applicationId, row.id)} title="Mark/Unmark as Favorite">
                                {row.isInFavorites ? <MdStar size={21} /> : <MdStarOutline size={21} />}
                              </IconButton>
                            )}
                            {row.canonicalId && (
                              <IconButton
                                size="small"
                                component="a"
                                href={`https://dayforce.atlassian.net/issues/?jql=issuetype%20%3D%20SECBUG%20AND%20%22Canonical%20ID%5BShort%20text%5D%22%20~%20%27%22${row.canonicalId}%22%27%20and%20status%20%21%3D%20Closed%20AND%20status%20%21%3D%20Done`}
                                target="_blank"
                                title="View Related JIRA Issues"
                              >
                                <SiJira />
                              </IconButton>
                            )}
                            {(row.repoUrl || row.githubRepoLink) && (
                              <>
                                <IconButton
                                  size="small"
                                  component="a"
                                  href={row.repoUrl || row.githubRepoLink}
                                  target="_blank"
                                  title="View Repository"
                                >
                                  <FaGithub />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => handleSecretsScanner(row.repoUrl || row.githubRepoLink)}
                                  title="View Discovered Secrets"
                                >
                                  <RiLockPasswordLine />
                                </IconButton>
                              </>
                            )}
                          </>
                        )}
                      </TableCell>
                    )
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={filteredAndSortedData.length}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
        className="application-table-pagination"
      />

      <NewAssetModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
      />
    </div>
  );
};

export default AssetRecords;