import axios from "axios"
import {useQuery} from "react-query"
import {VeracodeApplication} from "./VeracodeApplication"
import {VeracodeApplicationList} from "./VeracodeApplicationList"
import {VscError} from "react-icons/vsc";
import AnimatedShieldLoader from '../shared/AnimatedShieldLoader';

const fetchApplications = async (): Promise<VeracodeApplication[]> => {
    let response = await axios.get("/veracode/apps").then(r => r.data)
    console.log(response)
    return response
}

const sortToShowSipFirst = (a: VeracodeApplication, b: VeracodeApplication): number => {
    if (a.name.startsWith("SIP") && b.name.startsWith("SIP")) {
        return a.name.localeCompare(b.name)
    }

    if (a.name.startsWith("SIP")) {
        return -1;
    }

    if (b.name.startsWith("SIP")) {
        return 1;
    }

    return a.name.localeCompare(b.name);
}

export const VeracodeApplications: React.FC = () => {
    const {
        data,
        isLoading,
        isSuccess
    } = useQuery("veracode/apps", fetchApplications)

    if (isLoading) {
        return <div id="loader">
            <AnimatedShieldLoader />
        </div>
    }

    if (isSuccess) {
        return (
            <>
                <VeracodeApplicationList applications={data.sort((a, b) => sortToShowSipFirst(a, b))}/>
            </>
        )
    }

    return <><VscError /> Failed to load, please, check console</>
}
