import React, { useEffect, useState } from 'react';
import { LineChartRequest } from './LineChartRequest';
import { CartesianGrid, Label, Legend, Line, LineChart, Tooltip, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import JiraStatisticFilter from '../shared/JiraStatisticFilter';
import axios from 'axios';
import AnimatedShieldLoader from '../shared/AnimatedShieldLoader';

import './VulnerabilityRemediationExpandedChart.scss';

import { JiraStatisticAppFamilyFilter, JiraStatisticScale } from '../shared/JiraStatisticFilterConstants';
import LineChartBase from './LineChartBase';
import { RemediateTime } from './RemediateTime';
import { ChartPropsBase } from './ChartPropsBase';

const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="tooltip-label">{`Date: ${label}`}</p>
                {payload.map((entry: any, index: number) => (
                    <p key={index} style={{ color: entry.color }}>
                        {`${entry.name}: ${entry.value} days`}
                    </p>
                ))}
            </div>
        );
    }
    return null;
};

const RemediateTimeChart: React.FC<ChartPropsBase> = ({
    applications, 
    productFamilies,
    psArchitects,
    securityChampions,
    devLeads,
    productSecurityPM,
    productManager,
    tags
    }) => {
    const defaultData: RemediateTime[]= [];
    const [data, setData] = useState<RemediateTime[]>(defaultData);
    const [isLoading, setIsLoading] = useState(false);
    const {
        selectedNames,
        selectedScale,
        selectedDateRange,
        selectedNameType,
        appChanged,
        appFamilyChanged,
        scaleChanged,
        dateRangeChanged,
        psArchitectsChanged,
        securityChampionsChanged,
        devLeadsChanged,
        productSecurityPMChanged,
        productManagerChanged,
        tagsChanged,
        customizedAxisTick,
    } = LineChartBase();

    const requestData = async () => {
        setIsLoading(true);
        try {
            let request: LineChartRequest = {
                scale: selectedScale ?? JiraStatisticScale.Day,
                startDate: selectedDateRange.startDate ?? new Date(),
                endDate: selectedDateRange.endDate ?? new Date(),
                nameType: selectedNameType ?? JiraStatisticAppFamilyFilter.Application,
                selectedNames: selectedNames ?? []
            }
            
            const response = await axios.post('/jirastatistic/getRemediation', request)
            setData(response.data.map((x: RemediateTime) => {
                x.averageCriticalRemediationTime = Math.ceil(x.averageCriticalRemediationTime);
                x.averageHighRemediationTime = Math.ceil(x.averageHighRemediationTime);
                x.averageMediumRemediationTime = Math.ceil(x.averageMediumRemediationTime);
                x.averageLowRemediationTime = Math.ceil(x.averageLowRemediationTime);
                return x;
            }));
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if(selectedNames && selectedNames.length > 0){
            requestData();
        }
        // eslint-disable-next-line
    }, [selectedNames, selectedScale, selectedDateRange, selectedNameType]);

    const renderChart = () => {
        if (isLoading) {
            return (
                <div className='chart-pair' style={{ height: '400px' }}>
                    <div style={{ position: 'relative', height: '100%' }}>
                        <div style={{ 
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <AnimatedShieldLoader />
                        </div>
                    </div>
                </div>
            );
        }

        if (data.length === 0) {
            return <label className='text-info'>No data available</label>;
        }

        return (
            <div className='chart-pair'>
                <div className='one-chart'>
                    <ResponsiveContainer height={400} width="100%">
                        <LineChart 
                            data={data}
                            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend 
                                height={36}
                                wrapperStyle={{
                                    paddingTop: '20px'
                                }}
                            />
                            <XAxis 
                                dataKey="timeStamp" 
                                padding="no-gap" 
                                interval={0} 
                                tick={customizedAxisTick}
                                height={60}
                            />
                            <YAxis>
                                <Label 
                                    angle={-90} 
                                    position="insideLeft"
                                    style={{ textAnchor: 'middle' }}
                                >
                                    Days count
                                </Label>
                            </YAxis>
                            <Line 
                                name="Critical"
                                strokeWidth={2} 
                                type="monotone" 
                                dataKey="averageCriticalRemediationTime" 
                                stroke="#fd4433" 
                                dot={false}
                                activeDot={{ r: 6 }}
                            />
                            <Line 
                                name="High"
                                strokeWidth={2} 
                                type="monotone" 
                                dataKey="averageHighRemediationTime" 
                                stroke="#FF6859" 
                                dot={false}
                                activeDot={{ r: 6 }}
                            />
                            <Line 
                                name="Medium"
                                strokeWidth={2} 
                                type="monotone" 
                                dataKey="averageMediumRemediationTime" 
                                stroke="#FFCF44" 
                                dot={false}
                                activeDot={{ r: 6 }}
                            />
                            <Line 
                                name="Low"
                                strokeWidth={2} 
                                type="monotone" 
                                dataKey="averageLowRemediationTime" 
                                stroke="#f1f997" 
                                dot={false}
                                activeDot={{ r: 6 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        );
    };

    return (
        <div className='statistic-pane'>
            <div className='chart-title'>
                <h4>Mean Time To Remediate</h4>
            </div>
            <JiraStatisticFilter 
                applications={applications}
                psArchitects={psArchitects}
                securityChampions={securityChampions}
                devLeads={devLeads}
                productSecurityPM={productSecurityPM}
                productManager={productManager}
                tags={tags}
                applicationChangedCallback={appChanged}
                psArchitectsCallback={psArchitectsChanged}
                securityChampionsCallback={securityChampionsChanged}
                devLeadsCallback={devLeadsChanged}
                productSecurityPMCallback={productSecurityPMChanged}
                productManagerCallback={productManagerChanged}
                tagsCallback={tagsChanged}
                productFamilies={productFamilies} 
                productFamilyChangedCallback={appFamilyChanged}
                scaleChangedCallback={scaleChanged}
                dateRangeChangedCallback={dateRangeChanged}
                defaultAppFamilyFilter={selectedNameType}
                defaultStartDate={selectedDateRange.startDate}
                defaultEndDate={selectedDateRange.endDate}
                defaultScale={selectedScale}
                showScale={true}
                id="remediation"
            />
            <div className='chart'>
                {renderChart()}
            </div>
        </div>
    );
};

export default RemediateTimeChart;