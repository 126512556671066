import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { BsX, BsChevronDown, BsCalendar, BsCheck, BsSearch } from 'react-icons/bs';
import { MultiselectOption } from '../shared/Multiselect/MultiselectOption';
import { DateRangePreset } from './types'
import './ChartFilters.scss';

export const SCAN_TYPES = [
    { id: 'SAST', label: 'SAST' },
    { id: 'DAST', label: 'DAST' },
    { id: 'SCA', label: 'SCA' },
    { id: 'Pentest', label: 'Pentest' },
    { id: 'Bug Bounty', label: 'Bug Bounty' }
];

interface FilterSection {
    id: string;
    title: string;
    options: MultiselectOption[];
}

interface ChartFiltersProps {
    isOpen: boolean;
    onClose: () => void;
    onApplyFilters: (chartId: string) => void;
    applications: MultiselectOption[];
    productFamilies: MultiselectOption[];
    psArchitects: MultiselectOption[];
    securityChampions: MultiselectOption[];
    devLeads: MultiselectOption[];
    productSecurityPM: MultiselectOption[];
    productManager: MultiselectOption[];
    tags: MultiselectOption[];
    chartId: string;
    defaultValues: {
        startDate: Date;
        endDate: Date;
        scale: string;
        appFamilyFilter: string;
        selectedScanTypes?: string[];
    };
    callbacks: {
        applicationChangedCallback: (selected: MultiselectOption[]) => Promise<void>;
        productFamilyChangedCallback: (selected: MultiselectOption[]) => Promise<void>;
        psArchitectsCallback: (selected: MultiselectOption[]) => Promise<void>;
        securityChampionsCallback: (selected: MultiselectOption[]) => Promise<void>;
        devLeadsCallback: (selected: MultiselectOption[]) => Promise<void>;
        productSecurityPMCallback: (selected: MultiselectOption[]) => Promise<void>;
        productManagerCallback: (selected: MultiselectOption[]) => Promise<void>;
        tagsCallback: (selected: MultiselectOption[]) => Promise<void>;
        scanTypeCallback?: (selected: MultiselectOption[]) => Promise<void>;
        scaleChangedCallback: (scale: string) => Promise<void>;
        dateRangeChangedCallback: (startDate: Date, endDate: Date) => Promise<void>;
    };
}

export const ChartFilters: React.FC<ChartFiltersProps> = ({
    isOpen,
    onClose,
    onApplyFilters,
    applications,
    productFamilies,
    psArchitects,
    securityChampions,
    devLeads,
    productSecurityPM,
    productManager,
    chartId,
    defaultValues,
    callbacks
}) => {
    const [activeFilterType, setActiveFilterType] = useState<string | null>(null);
    const [expandedSection, setExpandedSection] = useState<string | null>(null);
    const [searchTerms, setSearchTerms] = useState<Record<string, string>>({});
    const [activeDatePreset, setActiveDatePreset] = useState<DateRangePreset | null>(null);
    const [selectedScanTypes, setSelectedScanTypes] = useState<string[]>(
        defaultValues.selectedScanTypes || ['all']
    );

    const [filters, setFilters] = useState({
        applications: [] as MultiselectOption[],
        productFamilies: [] as MultiselectOption[],
        psArchitects: [] as MultiselectOption[],
        securityChampions: [] as MultiselectOption[],
        devLeads: [] as MultiselectOption[],
        productSecurityPM: [] as MultiselectOption[],
        productManager: [] as MultiselectOption[],
        tags: [] as MultiselectOption[],
        scale: defaultValues.scale,
        dateRange: {
            startDate: defaultValues.startDate,
            endDate: defaultValues.endDate
        }
    });

    const timeScales = [
        { id: 'day', label: 'Daily' },
        { id: 'week', label: 'Weekly' },
        { id: 'month', label: 'Monthly' }
    ];

    useEffect(() => {
        if (productFamilies.length > 0) {
            setFilters(prev => ({
                ...prev,
                productFamilies: productFamilies,
            }));
            setActiveFilterType('productFamilies');
        }
    }, [productFamilies]);

    useEffect(() => {
        const isPresetDate = (date: Date, preset: DateRangePreset | null): boolean => {
            if (!preset) return false;

            const today = new Date();
            if (preset === 'YTD') {
                const ytdStart = new Date(today.getFullYear(), 0, 1);
                return date.toDateString() === ytdStart.toDateString();
            } else {
                const presetStart = new Date();
                presetStart.setDate(today.getDate() - parseInt(preset));
                return date.toDateString() === presetStart.toDateString();
            }
        };

        const { startDate } = filters.dateRange;
        if (!isPresetDate(startDate, activeDatePreset)) {
            setActiveDatePreset(null);
        }
    }, [filters.dateRange, activeDatePreset]);

    const filterSections: FilterSection[] = useMemo(() => [
        { id: 'productFamilies', title: 'Product Families', options: productFamilies },
        { id: 'devLeads', title: 'Dev Leads', options: devLeads },
        { id: 'applications', title: 'Applications', options: applications },
        { id: 'securityChampions', title: 'Security Champions', options: securityChampions },
        { id: 'psArchitects', title: 'PS Architects', options: psArchitects },
        { id: 'productSecurityPM', title: 'Product Security PM', options: productSecurityPM },
        { id: 'productManager', title: 'Product Manager', options: productManager }
    ], [
        productFamilies,
        devLeads,
        applications,
        securityChampions,
        psArchitects,
        productSecurityPM,
        productManager
    ]);

    const handleScanTypeToggle = (scanType: string) => {
        let newTypes: string[];

        if (scanType === 'all') {
            newTypes = SCAN_TYPES.map(st => st.id);
        } else {
            if (selectedScanTypes.includes(scanType)) {
                newTypes = selectedScanTypes.filter(t => t !== scanType);
                if (newTypes.length === 0) {
                    newTypes = SCAN_TYPES.map(st => st.id);
                }
            } else {
                newTypes = selectedScanTypes.length === SCAN_TYPES.length
                    ? [scanType]
                    : [...selectedScanTypes, scanType];
            }
        }

        setSelectedScanTypes(newTypes);
        if (callbacks.scanTypeCallback) {
            callbacks.scanTypeCallback(
                newTypes.map(type => {
                    const scanType = SCAN_TYPES.find(st => st.id === type);
                    return { value: type, label: scanType?.label || type };
                })
            );
        }
    };

    const getFilteredOptions = useCallback((sectionId: string, options: MultiselectOption[]) => {
        const searchTerm = (searchTerms[sectionId] || '').toLowerCase();
        if (!searchTerm) return options;

        return options.filter(option =>
            (option.label || option.value).toLowerCase().includes(searchTerm)
        );
    }, [searchTerms]);

    const clearFilterSection = useCallback((sectionId: string, e: React.MouseEvent) => {
        e.stopPropagation();
        setFilters(prev => ({
            ...prev,
            [sectionId]: []
        }));
    }, []);

    const handleDatePreset = (preset: DateRangePreset) => {
        setActiveDatePreset(preset);
        const endDate = new Date();
        let startDate = new Date();

        if (preset === 'YTD') {
            startDate = new Date(endDate.getFullYear(), 0, 1);
        } else {
            startDate.setDate(endDate.getDate() - parseInt(preset));
        }

        setFilters(prev => ({
            ...prev,
            dateRange: {
                startDate,
                endDate
            }
        }));
    };

    const handleSectionToggle = (sectionId: string) => {
        setExpandedSection(expandedSection === sectionId ? null : sectionId);
    };

    const handleOptionSelect = useCallback((sectionId: string, option: MultiselectOption | 'all') => {
        setActiveFilterType(sectionId);

        setFilters(prev => {
            const newState = {
                ...prev,
                applications: sectionId === 'applications' ? prev.applications : [],
                productFamilies: sectionId === 'productFamilies' ? prev.productFamilies : [],
                psArchitects: sectionId === 'psArchitects' ? prev.psArchitects : [],
                securityChampions: sectionId === 'securityChampions' ? prev.securityChampions : [],
                devLeads: sectionId === 'devLeads' ? prev.devLeads : [],
                productSecurityPM: sectionId === 'productSecurityPM' ? prev.productSecurityPM : [],
                productManager: sectionId === 'productManager' ? prev.productManager : [],
                tags: sectionId === 'tags' ? prev.tags : []
            };

            if (option === 'all') {
                const allOptions = filterSections.find(section => section.id === sectionId)?.options || [];
                return {
                    ...newState,
                    [sectionId]: allOptions
                };
            }

            const currentOptions = newState[sectionId] as MultiselectOption[];
            const isSelected = currentOptions.some(item => item.value === option.value);

            return {
                ...newState,
                [sectionId]: isSelected
                    ? currentOptions.filter(item => item.value !== option.value)
                    : [...currentOptions, option]
            };
        });
    }, [filterSections]);

    const handleApplyFilters = async () => {
        if (filters.applications.length) {
            await callbacks.applicationChangedCallback(filters.applications);
        }
        if (filters.productFamilies.length) {
            await callbacks.productFamilyChangedCallback(filters.productFamilies);
        }
        if (filters.psArchitects.length) {
            await callbacks.psArchitectsCallback(filters.psArchitects);
        }
        if (filters.securityChampions.length) {
            await callbacks.securityChampionsCallback(filters.securityChampions);
        }
        if (filters.devLeads.length) {
            await callbacks.devLeadsCallback(filters.devLeads);
        }
        if (filters.productSecurityPM.length) {
            await callbacks.productSecurityPMCallback(filters.productSecurityPM);
        }
        if (filters.productManager.length) {
            await callbacks.productManagerCallback(filters.productManager);
        }
        if (filters.tags.length) {
            await callbacks.tagsCallback(filters.tags);
        }

        await callbacks.scaleChangedCallback(filters.scale);
        await callbacks.dateRangeChangedCallback(
            filters.dateRange.startDate,
            filters.dateRange.endDate
        );

        onApplyFilters(chartId);
        onClose();
    };

    const renderFilterSection = (section: FilterSection) => {
        const filteredOptions = getFilteredOptions(section.id, section.options);

        return (
            <div
                key={section.id}
                className={`filter-section ${expandedSection === section.id ? 'expanded' : ''} 
                           ${activeFilterType === section.id ? 'active' : ''}`}
            >
                <div className="filter-section__header" onClick={() => handleSectionToggle(section.id)}>
                    <h4>{section.title}</h4>
                    <div className="filter-section__header-right">
                        {filters[section.id].length > 0 && (
                            <div className="filter-count">
                                <span>{filters[section.id].length} selected</span>
                                <button
                                    className="clear-btn"
                                    onClick={(e) => clearFilterSection(section.id, e)}
                                >
                                    <BsX size={16} />
                                </button>
                            </div>
                        )}
                        <BsChevronDown className={expandedSection === section.id ? 'rotated' : ''} />
                    </div>
                </div>
                {expandedSection === section.id && (
                    <div className="filter-section__content">
                        <div className="filter-section__search">
                            <BsSearch className="search-icon" />
                            <input
                                type="text"
                                placeholder={`Search ${section.title.toLowerCase()}...`}
                                value={searchTerms[section.id] || ''}
                                onChange={(e) => setSearchTerms(prev => ({
                                    ...prev,
                                    [section.id]: e.target.value
                                }))}
                                onClick={(e) => e.stopPropagation()}
                            />
                        </div>
                        <div className="filter-section__options">
                            <div
                                className={`filter-option ${filters[section.id].length === section.options.length ? 'selected' : ''}`}
                                onClick={() => handleOptionSelect(section.id, 'all')}
                            >
                                <span>All {section.title}</span>
                                {filters[section.id].length === section.options.length && (
                                    <BsCheck className="check-icon" />
                                )}
                            </div>
                            {filteredOptions.map(option => (
                                <div
                                    key={option.value}
                                    className={`filter-option ${filters[section.id].some(item => item.value === option.value) ? 'selected' : ''}`}
                                    onClick={() => handleOptionSelect(section.id, option)}
                                >
                                    <span>{option.label || option.value}</span>
                                    {filters[section.id].some(item => item.value === option.value) && (
                                        <BsCheck className="check-icon" />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    if (!isOpen) return null;

    return (
        <div className="chart-filters">
            <div className="chart-filters__header">
                <h3>Filter Options</h3>
                <button className="chart-filters__close-btn" onClick={onClose}>
                    <BsX size={30} />
                </button>
            </div>

            <div className="chart-filters__body">
                <div className="chart-filters__body-left">
                    <div className="chart-filters__time-controls">
                        {chartId === 'vulnerability' && (
                            <div className="time-scale">
                                <h4>Scan Type</h4>
                                <div className="time-scale__options">
                                    {SCAN_TYPES.map(scanType => (
                                        <button
                                            key={scanType.id}
                                            className={`time-scale__btn ${selectedScanTypes.includes(scanType.id) ? 'active' : ''}`}
                                            onClick={() => handleScanTypeToggle(scanType.id)}
                                        >
                                            {scanType.label}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}

                        <div className="time-scale">
                            <h4>Time Scale</h4>
                            <div className="time-scale__options">
                                {timeScales.map(scale => (
                                    <button
                                        key={scale.id}
                                        className={`time-scale__btn ${filters.scale === scale.id ? 'active' : ''}`}
                                        onClick={() => setFilters(prev => ({ ...prev, scale: scale.id }))}
                                    >
                                        {scale.label}
                                    </button>
                                ))}
                            </div>
                        </div>

                        <div className="date-range">
                            <h4>Date Range</h4>
                            <div className="date-range__presets">
                                <button
                                    className={`preset-btn ${activeDatePreset === '30' ? 'active' : ''}`}
                                    onClick={() => handleDatePreset('30')}
                                >
                                    Last 30 Days
                                </button>
                                <button
                                    className={`preset-btn ${activeDatePreset === '90' ? 'active' : ''}`}
                                    onClick={() => handleDatePreset('90')}
                                >
                                    Last 90 Days
                                </button>
                                <button
                                    className={`preset-btn ${activeDatePreset === 'YTD' ? 'active' : ''}`}
                                    onClick={() => handleDatePreset('YTD')}
                                >
                                    YTD
                                </button>
                            </div>
                            <div className="date-range__inputs">
                                <div className="date-input">
                                    <BsCalendar />
                                    <input
                                        type="date"
                                        value={filters.dateRange.startDate.toISOString().split('T')[0]}
                                        onChange={e => setFilters(prev => ({
                                            ...prev,
                                            dateRange: {
                                                ...prev.dateRange,
                                                startDate: new Date(e.target.value)
                                            }
                                        }))}
                                    />
                                </div>
                                <div className="date-input">
                                    <BsCalendar />
                                    <input
                                        type="date"
                                        value={filters.dateRange.endDate.toISOString().split('T')[0]}
                                        onChange={e => setFilters(prev => ({
                                            ...prev,
                                            dateRange: {
                                                ...prev.dateRange,
                                                endDate: new Date(e.target.value)
                                            }
                                        }))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="chart-filters__body-right">
                    {filterSections.map(renderFilterSection)}
                </div>
            </div>

            <div className="chart-filters__footer">
                <button className="btn-cancel" onClick={onClose}>Cancel</button>
                <button className="btn-apply" onClick={handleApplyFilters}>Apply Filters</button>
            </div>
        </div>
    );
};

export default ChartFilters;