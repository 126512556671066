import React, { useEffect, useState } from 'react';
import { LineChartRequest } from './LineChartRequest';
import { MultiselectOption } from '../shared/Multiselect/MultiselectOption';
import { CartesianGrid, Label, Legend, Line, LineChart, Tooltip, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import JiraStatisticFilter from '../shared/JiraStatisticFilter';
import { RatioOfIntroducedResolved } from './RatioOfIntroducedResolved';
import axios from 'axios';
import AnimatedShieldLoader from '../shared/AnimatedShieldLoader';

import './VulnerabilityRemediationExpandedChart.scss';

import { JiraStatisticAppFamilyFilter, JiraStatisticScale } from '../shared/JiraStatisticFilterConstants';
import LineChartBase from './LineChartBase';

interface RatioOfIntroducedResolvedChartProps {
    applications: MultiselectOption[];
    productFamilies: MultiselectOption[];
    psArchitects: MultiselectOption[];
    securityChampions: MultiselectOption[];
    devLeads: MultiselectOption[];
    productSecurityPM: MultiselectOption[];
    productManager: MultiselectOption[];
    tags: MultiselectOption[];
}

const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="tooltip-label">{`Date: ${label}`}</p>
                {payload.map((entry: any, index: number) => (
                    <p key={index} style={{ color: entry.color }}>
                        {`${entry.name}: ${entry.value}`}
                    </p>
                ))}
            </div>
        );
    }
    return null;
};

const renderChart = (isLoading: boolean, chart: React.ReactNode, height = 400) => {
    if (isLoading) {
        return (
            <div className='chart-pair' style={{ height: `${height}px` }}>
                <div className='one-chart' style={{ position: 'relative', height: '100%' }}>
                    <div style={{ 
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <AnimatedShieldLoader />
                    </div>
                </div>
            </div>
        );
    }
    return chart;
};

const RatioOfIntroducedResolvedChart: React.FC<RatioOfIntroducedResolvedChartProps> = ({ 
    applications, 
    productFamilies,
    psArchitects,
    securityChampions,
    devLeads,
    productSecurityPM,
    productManager,
    tags
  }) => {
    const defaultData: RatioOfIntroducedResolved[] = [];
    const [data, setData] = useState<RatioOfIntroducedResolved[]>(defaultData);
    const [isLoading, setIsLoading] = useState(false);
    const {
        selectedNames,
        selectedScale,
        selectedDateRange,
        selectedNameType,
        appChanged,
        appFamilyChanged,
        scaleChanged,
        dateRangeChanged,
        psArchitectsChanged,
        securityChampionsChanged,
        devLeadsChanged,
        productSecurityPMChanged,
        productManagerChanged,
        tagsChanged,
        customizedAxisTick,
    } = LineChartBase();

    const requestData = async () => {
        setIsLoading(true);
        try {
            let request: LineChartRequest = {
                scale: selectedScale ?? JiraStatisticScale.Day,
                startDate: selectedDateRange.startDate ?? new Date(),
                endDate: selectedDateRange.endDate ?? new Date(),
                nameType: selectedNameType ?? JiraStatisticAppFamilyFilter.Application,
                selectedNames: selectedNames ?? []
            }
            
            const response = await axios.post('/jirastatistic/getRatioOfIntroducedResolved', request)
            setData(response.data);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if(selectedNames && selectedNames.length > 0){
            requestData();
        }
        // eslint-disable-next-line
    }, [selectedNames, selectedScale, selectedDateRange, selectedNameType]);

    return (
        <div className='statistic-pane'>
            <div className='chart-title'>
                <h4>Ratio Of Introduced/Resolved</h4>
            </div>
            <JiraStatisticFilter 
                applications={applications}
                psArchitects={psArchitects}
                securityChampions={securityChampions}
                devLeads={devLeads}
                productSecurityPM={productSecurityPM}
                productManager={productManager}
                tags={tags}
                applicationChangedCallback={appChanged}
                psArchitectsCallback={psArchitectsChanged}
                securityChampionsCallback={securityChampionsChanged}
                devLeadsCallback={devLeadsChanged}
                productSecurityPMCallback={productSecurityPMChanged}
                productManagerCallback={productManagerChanged}
                tagsCallback={tagsChanged}
                productFamilies={productFamilies} 
                productFamilyChangedCallback={appFamilyChanged}
                scaleChangedCallback={scaleChanged}
                dateRangeChangedCallback={dateRangeChanged}
                defaultAppFamilyFilter={selectedNameType}
                defaultStartDate={selectedDateRange.startDate}
                defaultEndDate={selectedDateRange.endDate}
                defaultScale={selectedScale}
                showScale={true}
                id="introduced"
            />
            
            <div className='chart'>
                {renderChart(isLoading,
                    data.length === 0 ? (
                        <label className='text-info'>No data available</label>
                    ) : (
                        <div className='chart-pair'>
                            <div className='one-chart'>
                                <ResponsiveContainer height={400} width="100%">
                                    <LineChart 
                                        data={data}
                                        margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Legend 
                                            height={36}
                                            wrapperStyle={{
                                                paddingTop: '20px'
                                            }}
                                        />
                                        <XAxis 
                                            dataKey="timeStamp" 
                                            padding="no-gap" 
                                            interval={0} 
                                            tick={customizedAxisTick}
                                            height={60}
                                        />
                                        <YAxis>
                                            <Label 
                                                angle={-90} 
                                                position="insideLeft"
                                                style={{ textAnchor: 'middle' }}
                                            >
                                                Issues count
                                            </Label>
                                        </YAxis>
                                        <Line 
                                            name="SAST"
                                            strokeWidth={2} 
                                            type="monotone" 
                                            dataKey="sast" 
                                            stroke="#fd4433" 
                                            dot={false}
                                            activeDot={{ r: 6 }}
                                        />
                                        <Line 
                                            name="DAST"
                                            strokeWidth={2} 
                                            type="monotone" 
                                            dataKey="dast" 
                                            stroke="#1EB980" 
                                            dot={false}
                                            activeDot={{ r: 6 }}
                                        />
                                        <Line 
                                            name="SCA"
                                            strokeWidth={2} 
                                            type="monotone" 
                                            dataKey="sca" 
                                            stroke="#82ca9d" 
                                            dot={false}
                                            activeDot={{ r: 6 }}
                                        />
                                        <Line 
                                            name="Pentest"
                                            strokeWidth={2} 
                                            type="monotone" 
                                            dataKey="pentest" 
                                            stroke="#f1f997" 
                                            dot={false}
                                            activeDot={{ r: 6 }}
                                        />
                                        <Line 
                                            name="Bug Bounty"
                                            strokeWidth={2} 
                                            type="monotone" 
                                            dataKey="bugBounty" 
                                            stroke="#0088FE" 
                                            dot={false}
                                            activeDot={{ r: 6 }}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

export default RatioOfIntroducedResolvedChart;