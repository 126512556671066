import React, { useEffect, useState } from 'react';

interface AnimatedNumberProps {
  value: number;
  formatValue?: (value: number) => string;
}

export const AnimatedNumber: React.FC<AnimatedNumberProps> = ({ value, formatValue = (val) => val.toLocaleString() }) => {
  const [count, setCount] = useState(0);

  // Determine if the target value is a whole number
  const isWholeNumber = value % 1 === 0;

  useEffect(() => {
    let startTime: number;
    let animationFrame: number;
    const duration = 1500;

    const updateCount = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const percentage = Math.min(progress / duration, 1);

      const easeOutQuart = 1 - Math.pow(1 - percentage, 4);
      // If target is whole number, keep animation values as whole numbers
      const currentValue = easeOutQuart * value;
      setCount(isWholeNumber ? Math.floor(currentValue) : currentValue);

      if (progress < duration) {
        animationFrame = requestAnimationFrame(updateCount);
      } else {
        setCount(value);
      }
    };

    animationFrame = requestAnimationFrame(updateCount);
    return () => cancelAnimationFrame(animationFrame);
  }, [value, isWholeNumber]);

  return <span>{formatValue(count)}</span>;
};