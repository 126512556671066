import React, { useState, useEffect, useCallback } from 'react';
import { BsBarChartLine } from 'react-icons/bs';
import { MdOutlineFilterList } from "react-icons/md";
import axios from 'axios';
import { DynamicChart } from './DynamicChart';
import { ChartFilters } from './ChartFilters';
import FilterTags from './FilterTags';
import { TimeGranularity, VulnerabilityData, ChartMetricUpdate, COLORS } from './types';
import { MultiselectOption } from '../shared/Multiselect/MultiselectOption';
import './ReportingDashboard.scss';

// Get all scan types
const ALL_SCAN_TYPES = ['SAST', 'DAST', 'SCA', 'Pentest', 'Bug Bounty'];

interface DynamicVulnerabilityChartProps {
    // LineChartBase props
    selectedNames?: string[];
    selectedScale: string;
    selectedDateRange: { startDate: Date; endDate: Date };
    selectedNameType: string;
    appChanged: (selected: MultiselectOption[]) => Promise<void>;
    appFamilyChanged: (selected: MultiselectOption[]) => Promise<void>;
    psArchitectsChanged: (selected: MultiselectOption[]) => Promise<void>;
    securityChampionsChanged: (selected: MultiselectOption[]) => Promise<void>;
    devLeadsChanged: (selected: MultiselectOption[]) => Promise<void>;
    productSecurityPMChanged: (selected: MultiselectOption[]) => Promise<void>;
    productManagerChanged: (selected: MultiselectOption[]) => Promise<void>;
    tagsChanged: (selected: MultiselectOption[]) => Promise<void>;
    scaleChanged: (scale: string) => Promise<void>;
    dateRangeChanged: (startDate: Date, endDate: Date) => Promise<void>;

    // Data props
    applications: MultiselectOption[];
    productFamilies: MultiselectOption[];
    psArchitects: MultiselectOption[];
    securityChampions: MultiselectOption[];
    devLeads: MultiselectOption[];
    productSecurityPM: MultiselectOption[];
    productManager: MultiselectOption[];
    tags: MultiselectOption[];

    // Callback props
    onDataUpdate?: (update: ChartMetricUpdate) => void;
}

const processVulnerabilityData = (rawData: any[], selectedScanTypes: string[]): VulnerabilityData[] => {
    if (!rawData) return [];

    const normalizeDate = (timestamp: string) => {
        return new Date(timestamp).toISOString().split('T')[0];
    };

    let relevantData = selectedScanTypes.flatMap(scanType => {
        const scanData = rawData.find(item =>
            item.scanType?.toUpperCase() === scanType.toUpperCase()
        );
        return scanData?.statusCountsData || [];
    });

    const dataByTimestamp = new Map();
    relevantData.forEach(item => {
        const normalizedDate = normalizeDate(item.timeStamp);
        if (!dataByTimestamp.has(normalizedDate)) {
            dataByTimestamp.set(normalizedDate, {
                timeStamp: normalizedDate,
                open: 0,
                inProgress: 0,
                blocked: 0,
                ResolvedIssuesCount: 0
            });
        }

        const existing = dataByTimestamp.get(normalizedDate);
        existing.open += Math.ceil(item.open || 0);
        existing.inProgress += Math.ceil(item.inProgress || 0);
        existing.blocked += Math.ceil(item.blocked || 0);
        existing.ResolvedIssuesCount += Math.ceil(item.ResolvedIssuesCount || item.closed || 0);
    });

    return Array.from(dataByTimestamp.values())
        .sort((a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime());
};

export const DynamicVulnerabilityChart: React.FC<DynamicVulnerabilityChartProps> = ({
    selectedNames,
    selectedScale,
    selectedDateRange,
    selectedNameType,
    applications,
    productFamilies,
    psArchitects,
    securityChampions,
    devLeads,
    productSecurityPM,
    productManager,
    tags,
    appChanged,
    appFamilyChanged,
    psArchitectsChanged,
    securityChampionsChanged,
    devLeadsChanged,
    productSecurityPMChanged,
    productManagerChanged,
    tagsChanged,
    scaleChanged,
    dateRangeChanged,
    onDataUpdate
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<VulnerabilityData[]>([]);
    const [activeFilter, setActiveFilter] = useState(false);
    const [selectedScanTypes, setSelectedScanTypes] = useState<string[]>(ALL_SCAN_TYPES);

    const fetchData = useCallback(async () => {
        if (!selectedNames?.length) return;

        setIsLoading(true);
        try {
            const response = await axios.post('/jirastatistic/getVulnerabilityRemediationExpanded', {
                scale: selectedScale,
                startDate: selectedDateRange.startDate?.toISOString(),
                endDate: selectedDateRange.endDate?.toISOString(),
                nameType: selectedNameType,
                selectedNames
            });

            const processedData = processVulnerabilityData(response.data, selectedScanTypes);
            setData(processedData);
            onDataUpdate?.({
                data: processedData,
                timeGranularity: selectedScale as TimeGranularity
            });
        } catch (error) {
            console.error('Error fetching vulnerability data:', error);
        } finally {
            setIsLoading(false);
        }
    }, [selectedNames, selectedScale, selectedDateRange, selectedNameType, selectedScanTypes, onDataUpdate]);

    useEffect(() => {
        if (selectedNames?.length) {
            fetchData();
        }
    }, [selectedNames, selectedScale, selectedDateRange, selectedNameType, selectedScanTypes, fetchData]);

    const handleScanTypeChange = async (selected: MultiselectOption[]) => {
        const scanTypes = selected.length === 0
            ? ALL_SCAN_TYPES
            : selected.map(option => option.value);
        setSelectedScanTypes(scanTypes);
    };

    return (
        <div className="reporting-dashboard__chart-cell col-span-2">
            <div className="chart-header">
                <div className="chart-title">
                    <BsBarChartLine size={18} />
                    <h5>Vulnerability Status Trends</h5>
                    <FilterTags
                        selectedFilters={{
                            scale: selectedScale,
                            dateRange: selectedDateRange,
                            scanTypes: selectedScanTypes
                        }}
                    />
                </div>
                <button
                    className="filter-button"
                    onClick={() => setActiveFilter(!activeFilter)}
                >
                    <MdOutlineFilterList size={18} /> Filters
                </button>
            </div>
            <div className="chart-container">
                <DynamicChart
                    data={data}
                    config={{
                        dateRange: selectedDateRange,
                        chartType: 'bar',
                        granularity: selectedScale as TimeGranularity,
                        nameType: selectedNameType,
                        selectedNames: selectedNames || []
                    }}
                    loading={isLoading}
                    dataKeys={['open', 'inProgress', 'blocked']}
                    keyLabels={{
                        open: 'Open',
                        inProgress: 'In Progress',
                        blocked: 'Blocked'
                    }}
                    colors={[COLORS.info, COLORS.warning, COLORS.danger]}
                />
            </div>
            <ChartFilters
                isOpen={activeFilter}
                onClose={() => setActiveFilter(false)}
                onApplyFilters={() => {
                    fetchData();
                    setActiveFilter(false);
                }}
                applications={applications}
                productFamilies={productFamilies}
                psArchitects={psArchitects}
                securityChampions={securityChampions}
                devLeads={devLeads}
                productSecurityPM={productSecurityPM}
                productManager={productManager}
                tags={tags}
                chartId="vulnerability"
                defaultValues={{
                    startDate: selectedDateRange.startDate,
                    endDate: selectedDateRange.endDate,
                    scale: selectedScale,
                    appFamilyFilter: selectedNameType,
                    selectedScanTypes: selectedScanTypes
                }}
                callbacks={{
                    applicationChangedCallback: appChanged,
                    productFamilyChangedCallback: appFamilyChanged,
                    psArchitectsCallback: psArchitectsChanged,
                    securityChampionsCallback: securityChampionsChanged,
                    devLeadsCallback: devLeadsChanged,
                    productSecurityPMCallback: productSecurityPMChanged,
                    productManagerCallback: productManagerChanged,
                    tagsCallback: tagsChanged,
                    scaleChangedCallback: scaleChanged,
                    dateRangeChangedCallback: dateRangeChanged,
                    scanTypeCallback: handleScanTypeChange
                }}
            />
        </div>
    );
};

export default DynamicVulnerabilityChart;