import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import AnimatedShieldLoader from '../shared/AnimatedShieldLoader';
import { IoIosApps } from "react-icons/io";
import { MdFileCopy, MdInfoOutline } from "react-icons/md";
import { SiJira } from "react-icons/si";
import { RiGitRepositoryFill } from "react-icons/ri";
import { BsShieldFillCheck, BsShieldFillExclamation, BsShieldFillX, BsShieldSlash, BsShieldFillMinus } from "react-icons/bs";
import { ApplicationDetailsModel } from "./ApplicationDetailsModel";
import { ScanResultsList } from "../ScanResults/ScanResultsList";

import "./ApplicationDetails.scss";

const fetchData = async (appId: string | undefined): Promise<ApplicationDetailsModel> => {
    let appResponse = await axios.get(`/applications/details/${appId}`);
    let scansResponse = await axios.get(`/scan-results/${appId}`);

    let result: ApplicationDetailsModel = {
        application: appResponse.data.app,
        scanResults: scansResponse.data.scanResults
    }

    return result;
}

const ApplicationDetails: React.FC = () => {
    const params = useParams();
    const {
        data,
        isLoading
    } = useQuery(`/scan-results/${params.id}`, () => fetchData(params.id))

    const [isAppIdCopied, setIsAppIdCopied] = useState(false)

    const copyAppId = async () => {
        if(!data || !data.application) {return}
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(data.application.id);
        } else {
            document.execCommand('copy', true, data.application.id);
        }

        setIsAppIdCopied(true);
        setTimeout(() => {
            setIsAppIdCopied(false);
        }, 2000);
    }

    const enableScaScan = async () => {
        console.log('enabling...');
        if(!data || !data.application) {return}
        await axios.post("/applications/" + data.application.id + "/sca-enable");
        data.application.isScaEnabled = true;
        console.log('...done');
    }

    const getScaToken = async () => {
        if(!data || !data.application) {return}
        console.log('printing...');
        let response = await axios.get("/applications/" + data.application.id + "/sca-token").then(r => r.data);
        console.log(`token: ${response.token.length}`);

        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(response.token);
        } else {
            document.execCommand('copy', true, response.token);
        }
    }

    if (isLoading) {
        return <div id="loader">
            <AnimatedShieldLoader />
        </div>
    }

    return (
        <div>
            <div className="app-details-card">
                <h2>
                    <IoIosApps size={24}/> {data?.application.name}
                </h2>
                <div className="details-grid">
                    <div className="details-column">
                        <div className="details-element">
                            <div className="label">ScanHub GUID</div>
                            <div className="appId">
                                <span className="guid">{data?.application.id}</span>
                                {isAppIdCopied && <span className="appIdCopied">Copied!</span>}
                                {'clipboard' in navigator &&
                                    <span hidden className="action action-import" title="Copy Id" onClick={() => copyAppId()}>
                                        <MdFileCopy size={16}/>
                                    </span>
                                }
                            </div>
                        </div>
                        <div className="details-element">
                            <div className="label">Veracode GUID</div>
                            <div>{data?.application.externalId}</div>
                        </div>
                        <div className="details-element">
                            <div className="label">Canonical ID</div>
                            <div>{data?.application.canonicalId}</div>
                        </div>
                    </div>
                    <div className="details-column">
                        <div className="details-element">
                            <div className="label">Repo Name</div>
                            <div>{data?.application.repoName}</div>
                        </div>
                        <div className="details-element">
                            <div className="label">Repo URL</div>
                            <div><a href={data?.application.repoUrl} target="_blank" rel="noreferrer">{data?.application.repoUrl}</a></div>
                        </div>
                        <div className="details-element">
                            <div className="label">Support Tier</div>
                            <div>{data?.application.appSecSupportTierLevel}</div>
                        </div>
                    </div>
                    <div className="details-column">
                        <div className="details-element">
                            <div className="label">Policy</div>
                            <div>{data?.application.policyName}</div>
                        </div>
                        <div className="details-element">
                            <div className="label">Policy Compliance</div>
                            <div className="policy-data">
                                <div className="compliance-status" title={data?.application.policyComplianceStatus}>                
                                    {data?.application.policyComplianceStatus === "PASSED" && <BsShieldFillCheck size={20} style={{ color: "#1EB980" } } />}
                                    {data?.application.policyComplianceStatus === "DID_NOT_PASS" && <BsShieldFillX size={20} style={{ color: "#fd4433" }} />}
                                    {data?.application.policyComplianceStatus === "CONDITIONAL_PASS" && <BsShieldFillExclamation size={20} style={{ color: "#FFCF44" }} />}
                                    {data?.application.policyComplianceStatus === "NOT_ASSESSED" && <BsShieldSlash size={20} style={{ color: "grey" }} />}
                                    {data?.application.policyComplianceStatus === "DETERMINING" && <BsShieldFillMinus size={20} style={{ color: "grey" }} />}
                                </div>
                                <div className="severities">
                                    <span title="Critical severity" className="critical">C: {data?.application.criticalCount ?? 0}</span>
                                    <span title="High severity" className="high">H: {data?.application.highCount ?? 0}</span>
                                    <span title="Medium severity" className="medium">M: {data?.application.mediumCount ?? 0}</span>
                                    <span title="Low severity" className="low">L: {data?.application.lowCount ?? 0}</span>
                                </div>
                            </div>
                        </div>
                        <div className="details-element">
                            <div className="label">SAST Status</div>
                            <div>{data?.application.sastStatus}</div>
                        </div>
                        <div className="details-element">
                            <div className="label">Last Scan</div>
                            <div>{data?.application.lastCompletedScanDate ? new Date(data.application.lastCompletedScanDate).toISOString().replace('T', " ").split('.000Z')[0] : 'N/A'}</div>
                        </div>
                    </div>
                </div>
                <div className="actions-section">
                    {data?.application.repoUrl && (
                        <a href={data?.application.repoUrl} target="_blank" rel="noreferrer" className="link-icon">
                            <RiGitRepositoryFill size={18}/>
                        </a>
                    )}
                    {data?.application.canonicalId && (
                        <a href={`https://dayforce.atlassian.net/issues/?jql=issuetype%20%3D%20SECBUG%20AND%20%22Canonical%20ID%5BShort%20text%5D%22%20~%20%27%22${data.application.canonicalId}%22%27%20and%20status%20%21%3D%20Closed%20AND%20status%20%21%3D%20Done`} target="_blank" rel="noopener noreferrer" className="link-icon">
                            <SiJira size={18}/>
                        </a>
                    )}
                    <span hidden className="action action-import" title="Print to console" onClick={() => getScaToken()}>
                        <MdInfoOutline size={18} />
                    </span>
                    {data?.application.isScaEnabled ? (
                        <button disabled title="SCA Scanning is Enabled" className="scar-btn">
                            SCA-R Enabled
                        </button>
                    ) : (
                        <button title="Enable SCA Scan" onClick={() => enableScaScan()} className="scar-btn">
                            Enable SCA-R
                        </button>
                    )}
                </div>
            </div>
            <div>
                <ScanResultsList items={data?.scanResults || []} />
            </div>
        </div>
    );
}

export default ApplicationDetails;